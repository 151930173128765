<template>
    <div class="areas">
        <v-main>
            <v-row>
                <v-col>
                    <v-row class="controls">
                        <v-btn class="white--text" color="primary" data-id="AddNewButton" @click="createArea()">
                            {{ t('shared.resources.common.addNew') }}
                        </v-btn>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="areasStore.areas"
                        data-id="DataTable"
                        disable-pagination
                        disable-sort
                        hide-default-footer
                    >
                        <template #[`item.createdAt`]="{ item }">
                            {{ formatDate(item.createdAt) }}
                        </template>
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon class="mr-2" data-id="EditIcon" style="outline: none" v-bind="props" @click="editArea(item.id)">
                                        edit
                                    </v-icon>
                                </template>
                                <span>
                                    {{ t('shared.resources.common.edit') }}
                                </span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAreasStore } from './areasStore';

const areasStore = useAreasStore();
const { t } = useI18n();
const router = useRouter();

const headers = ref([
    {
        title: t('areas.resources.name'),
        value: 'name'
    },
    {
        title: t('areas.resources.stopsCount'),
        value: 'selectedStopsCount'
    },
    {
        title: t('areas.resources.linesCount'),
        value: 'selectedLinesCount'
    },
    {
        title: t('areas.resources.createdAt'),
        value: 'createdAt'
    },
    {
        title: t('shared.resources.common.edit'),
        value: 'action'
    }
]);

await areasStore.loadAreas();

function formatDate(date: string) {
    return ToLocalDate(date);
}

function editArea(item: number) {
    router.push({ path: `/areas/${item}` });
}

function createArea() {
    router.push({ name: 'AreasCreate' });
}
</script>

<style lang="scss" scoped>
.controls {
    justify-content: flex-end;
    margin: 12px 0;
}
</style>
