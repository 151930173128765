import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
const timeZone = 'Europe/Oslo';

export function ToLocalDate(date?: string | Date, dateFormat?: string) {
    if (!date) return '';

    const zonedDate = utcToZonedTime(date, timeZone);

    switch (dateFormat) {
        case 'ISOdate':
            return FormatDateISO(zonedDate);
        case 'time':
            return FormatTime(zonedDate);
        case 'date':
            return FormatDate(zonedDate);
        default:
            return FormatDateTime(zonedDate);
    }
}

function FormatDateTime(date: Date) {
    return format(date, 'dd.MM.yyyy HH:mm');
}

export function FormatDateISO(date: Date | string) {
    const d = new Date(date);
    return format(d, 'yyyy-MM-dd');
}

export function FormatDate(date: Date | string) {
    const d = new Date(date);
    return format(d, 'dd.MM.yyyy');
}

function FormatTime(date: Date) {
    return format(date, 'HH:mm');
}

export function DateTimeToUTC(date: string, time: string) {
    return zonedTimeToUtc(date + ' ' + time, timeZone);
}

export function DateToUTC(date: Date | string) {
    return zonedTimeToUtc(date, timeZone);
}
