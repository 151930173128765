import { Link } from '@/shared/models';

export interface Subscription {
    id: number;
    messageSubject: string;
    companies: Array<string>;
    lines: Array<string>;
    authorRoles: Array<AuthorRole>;
    subscribers: Array<Subscriber>;
}

export interface SubscriptionListItem extends Subscription {
    links: Link[];
}

export interface Subscriber {
    id?: number;
    fullName: string;
    email?: string;
    phone?: string;
    messageTypes: Array<string>;
    subscriptionTypes: Array<SubscriptionNotificationType>;
}

export enum SubscriptionNotificationType {
    None = 0,
    Email = 1,
    SMS = 2
}

export enum MessageNotificationType {
    Information = 0,
    Warning = 1,
    Cancellation = 2,
    EarlyTripEnd = 3,
    EquipmentFailure = 4,
    Full = 5,
    OnboardTicketMachine = 6,
    AtStopOther = 7
}

export enum AuthorRole {
    All = 'All',
    Driver = 'Driver',
    Editor = 'Editor',
    CompanyEditor = 'CompanyEditor'
}
