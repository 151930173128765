<template>
    <div class="summary-container">
        <div v-if="companies.length > 0" class="companies-summary">
            <div class="company-title">
                {{ t('messages.resources.companyScopeSummaryTitle', 'Valgt operatør') }}
            </div>
            <div class="selected-companies">
                <div v-for="company in companies" :key="company.id" class="company" @click="emit('removeCompany', company)">
                    <span>{{ `${company.name} (${company.id})` }}</span>
                    <span class="close-icon">
                        <v-icon icon="mdi-close" size="small"></v-icon>
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="summary-nodata">
            {{ t('messages.resources.companyScopeSummaryNoCompanies', 'Velg en operatør i tabellen.') }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Receiver } from '../../messagesModels';

const { t } = useI18n();

interface Props {
    companies: Receiver[];
}
const emit = defineEmits<{
    (e: 'removeCompany', company: Receiver);
}>();

defineProps<Props>();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.companies-summary {
    padding-bottom: 16px;
}

.company-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.summary-nodata,
.selected-companie {
    font-size: 14px;
}

.company {
    cursor: pointer;
    display: flex;

    .close-icon {
        visibility: hidden;
        margin-left: 2px;
        margin-top: -1px;
    }

    &:hover {
        .close-icon {
            visibility: visible;
            opacity: 0.5;
        }
    }
}
</style>
