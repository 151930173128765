<template>
    <v-container>
        <v-row class="controls" no-gutters>
            <v-col>
                <v-text-field
                    v-model="searchQuery"
                    class="search"
                    append-inner-icon="mdi-magnify"
                    color="primary"
                    data-id="SearchTextField"
                    hide-details
                    variant="outlined"
                    :label="t('messages.resources.searchInTable')"
                    :clearable="true"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-btn
                    v-if="hasOnlyOneCategory"
                    class="create-message"
                    color="primary"
                    data-id="AddNewButton"
                    @click="createMessage(categoryTemplates[0].name)"
                >
                    {{ t(`messages.resources.new${categoryTemplates[0].name}`) }}
                </v-btn>
                <v-btn v-else class="create-message" color="primary" data-id="AddNewButton">
                    {{ t(`messages.resources.${createButtonText}`) }}
                    <v-menu activator="parent">
                        <v-list>
                            <v-list-item v-for="category in categoryTemplates" :key="category.id" :value="category.name">
                                <v-list-item-title :data-id="`Category${category.name}`" @click="createMessage(category.name)">
                                    {{ category.label }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="table-wrap" :class="{ showSummary: showSummary }">
                <v-data-table
                    :headers="operatorHeaders"
                    :items="tableRows"
                    :items-per-page="DefaultPageSize"
                    :loading="loadingTable"
                    :page="page"
                    :row-props="rowProps"
                    data-id="DataTable"
                    disable-sort
                    @click:row="openSummary"
                >
                    <template #[`item.status`]="{ item }">
                        <v-tooltip bottom>
                            <template #activator="{ props: itemProps }">
                                <v-icon
                                    :color="getStatusIconColor(item.status)"
                                    :icon="getStatusIcon(item.status)"
                                    v-bind="itemProps"
                                    size="small"
                                ></v-icon>
                            </template>
                            <span>{{ getStatusTooltip(item.status) }}</span>
                        </v-tooltip>
                    </template>
                    <template #bottom>
                        <div class="pagination-wrap">
                            <div v-if="messagesStore.paging.totalItemCount !== undefined" class="totalMessages">
                                {{ t('messages.resources.messages') + ': ' + messagesStore.paging.totalItemCount }}
                            </div>
                            <div class="paging-wrap">
                                <v-pagination v-model="page" :length="messagesStore.paging.pageCount"></v-pagination>
                            </div>
                        </div>
                    </template>
                </v-data-table>
                <div v-if="showSummary">
                    <message-summary
                        v-model="messagesStore.message"
                        :loading="loadingMessage"
                        :loading-update="loadingMessageUpdate"
                        :message-template="messagesStore.currentMessageTemplate"
                        @update-message="updateMessage"
                        @close-summary="closeSummary"
                        @unpublish="unpublishMessage"
                        @publish="publishMessage"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '../messagesStore';
import { DefaultPageSize } from '@/shared/models';
import { DateToUTC } from '@/shared/DateFormatHelpers';
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { MessageListItem } from '../messagesModels';
import { MessageStatus } from '@/Features/DeviationMessages/messagesModels';
import MessageSummary from './MessageSummary.vue';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router';
import { useRouteQuery, integerTransformer } from 'vue-use-route-query';

interface TableRow {
    category: string;
    reason: string;
    lineName: string;
    departureTime: string;
    departure: string;
    startPublish: string;
    endPublish: string;
    author: string;
    status: MessageStatus;
    data: MessageListItem;
}

const router = useRouter();
const { t } = useI18n();
const messagesStore = useMessagesStore();

const page = useRouteQuery('page', 1, integerTransformer, { mode: 'push' });
const selectedMessageId = useRouteQuery('messageId', '');
const searchQuery = useRouteQuery('search', '');

const { query, categoryTemplates } = storeToRefs(messagesStore);
const currentDate = computed(() => DateToUTC(new Date()));
const loadingTable = ref(true);
const showSummary = ref(false);
const selectedRowId = ref('');
const hasOnlyOneCategory = computed(() => categoryTemplates.value.length === 1);
const createButtonText = computed(() => (hasOnlyOneCategory.value ? `new${categoryTemplates.value[0].name}` : 'newMessage'));
const loadingMessage = ref(false);
const loadingMessageUpdate = ref(false);

const operatorHeaders = computed<any[]>(() => [
    {
        title: t('messages.resources.listCategory'),
        key: 'category',
        sortable: false
    },
    {
        title: t('messages.resources.listReason'),
        key: 'reason',
        sortable: false
    },
    {
        title: t('messages.resources.listLineName'),
        key: 'lineName',
        sortable: false
    },
    {
        title: t('messages.resources.listDepartureTime'),
        key: 'departureTime',
        sortable: false
    },
    {
        title: t('messages.resources.listDeparture'),
        key: 'departure',
        sortable: false
    },
    {
        title: t('messages.resources.listStartPublish'),
        key: 'startPublish',
        sortable: false
    },
    {
        title: t('messages.resources.listEndPublish'),
        key: 'endPublish',
        sortable: false
    },
    {
        title: t('messages.resources.listAuthor'),
        key: 'author',
        sortable: false
    },
    {
        title: t('messages.resources.listStatus'),
        key: 'status',
        sortable: false,
        align: 'end'
    }
]);
const tableRows = computed<TableRow[]>(() => messagesStore.messages.map(x => toMessageListTableRow(x)));
query.value = searchQuery.value;
if (messagesStore.categoryTemplates.length < 1) await messagesStore.loadMessageCategories();
if (messagesStore.messageTemplates.length < 1) await messagesStore.loadMessageTemplates();
await messagesStore.loadMessages(page.value);
await openSelectedSummary();
loadingTable.value = false;

function toMessageListTableRow(message: MessageListItem) {
    return {
        category: message.subject,
        reason: message.reason,
        lineName: message.lineName || '',
        departureTime: ToLocalDate(message.departureTime, 'time') || '',
        departure: message.departure || '',
        startPublish: `${ToLocalDate(message.startPublish, 'date')} - ${ToLocalDate(message.startPublish, 'time')}`,
        endPublish: `${ToLocalDate(message.endPublish, 'date')} - ${ToLocalDate(message.endPublish, 'time')}`,
        author: message.author || '',
        status: message.status,
        data: message
    };
}

watch(
    searchQuery,
    _.debounce(async val => {
        loadingTable.value = true;
        page.value = 1;
        query.value = val;
        closeSummary();
        await messagesStore.loadMessages(page.value);
        loadingTable.value = false;
    }, 500)
);

watch(page, async () => {
    loadingTable.value = true;
    closeSummary();
    await messagesStore.loadMessages(page.value);
    loadingTable.value = false;
});

async function openSummary(_, row) {
    await openSummaryById(row.item.data.id);
}

async function openSummaryById(id: string) {
    loadingMessage.value = true;
    showSummary.value = true;
    selectedRowId.value = id;
    selectedMessageId.value = id;
    await messagesStore.loadMessage(id);
    loadingMessage.value = false;
}

async function updateMessage() {
    loadingMessageUpdate.value = true;
    await messagesStore.updateMessage();
    loadingMessageUpdate.value = false;
}

function closeSummary() {
    showSummary.value = false;
    selectedRowId.value = '';
    selectedMessageId.value = '';
}

async function unpublishMessage() {
    setMessageLoading(true);
    await messagesStore.unpublishMessage();
    await messagesStore.loadMessages(page.value);
    setMessageLoading(false);
    closeSummary();
}

async function publishMessage() {
    setMessageLoading(true);
    await messagesStore.publishMessage();
    await messagesStore.loadMessages(page.value);
    setMessageLoading(false);
    closeSummary();
}

function setMessageLoading(isLoading: boolean) {
    if (isLoading) {
        loadingTable.value = true;
        loadingMessage.value = true;
    } else {
        loadingMessage.value = false;
        loadingTable.value = false;
    }
}

function getStatusIconColor(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'primary';
    }

    if (status === MessageStatus.Unpublished) {
        return 'red-darken-4';
    }
    return 'grey';
}

function getStatusIcon(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return 'mdi-check-circle';
    }

    if (status === MessageStatus.Unpublished) {
        return 'mdi-close-circle';
    }
    return 'mdi-circle';
}

function getStatusTooltip(status: MessageStatus) {
    if (status === MessageStatus.Published) {
        return t('messages.resources.published');
    }

    if (status === MessageStatus.Unpublished) {
        return t('messages.resources.unpublished');
    }
    return t('messages.resources.draft');
}

function rowProps(row: any) {
    const message = row.item.data as MessageListItem;
    return {
        'data-message-id': message.id,
        'data-message-groupid': message.groupId,
        class: getRowClass(message)
    };
}

function getRowClass(message: MessageListItem) {
    if (isSelected(message)) {
        return 'selected-message';
    } else if (isActive(message)) {
        return 'active-message';
    }
    return undefined;
}

function isSelected(message: MessageListItem) {
    return message.id === selectedRowId.value;
}

function isActive(message: MessageListItem) {
    if (!message.startPublish || !message.endPublish) return false;
    const startPublish = DateToUTC(message.startPublish);
    const endPublish = DateToUTC(message.endPublish);
    return startPublish < currentDate.value && currentDate.value < endPublish && message.status === MessageStatus.Published;
}

function createMessage(category: string) {
    router.push({ name: routeNames.deviationMessagesCreate, query: { category: category } });
}

async function openSelectedSummary() {
    if (selectedMessageId.value) {
        const selectedRow = tableRows.value.find(x => x.data.id === selectedMessageId.value);
        if (selectedRow) {
            selectedRowId.value = selectedMessageId.value;
        }
        await openSummaryById(selectedMessageId.value);
    }
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.controls {
    padding-top: 42px;
    padding-bottom: 24px;

    .search {
        width: 300px;
    }

    .activeBtn {
        color: $white !important;
        background-color: $primary;
    }

    .create-message {
        float: right;
    }
}

.v-table :deep(.v-data-table-header__content) {
    font-size: 18px;
    font-weight: bold;
    color: $black;
}

.pagination-wrap {
    padding: 12px;

    .totalMessages {
        color: $grey;
        display: inline-block;
    }

    .paging-wrap {
        display: block;
        width: 33%;
        float: right;

        :deep(.v-pagination__list) {
            justify-content: right;
        }
    }
}

.table-wrap {
    display: grid;
    grid-template-columns: 100% 0;

    .item-summary {
        display: none;
    }

    &.showSummary {
        grid-template-columns: calc(100% - 372px) 372px;

        .item-summary {
            display: block;
        }
    }

    :deep(.selected-message) {
        background-color: $lightGrey;
    }
}
</style>
