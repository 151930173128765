import { defineStore } from 'pinia';
import { MpcStatisticsLine } from './mpcStatisticsModels';
import { Link } from '@/shared/models';
import { MpcStatisticsRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { FormatDateISO } from '@/shared/DateFormatHelpers';

export const useMpcStatisticsStore = defineStore('mpcStatistics', {
    state: () => ({
        mpcStatistics: new Array<MpcStatisticsLine>(),
        currentDate: FormatDateISO(new Date()),
        links: new Array<Link>()
    }),
    actions: {
        async loadMpcStatistics() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MpcStatisticsRels.getMpcStatistics);

            const currentDate = this.currentDate;
            const requestParams = new URLSearchParams();
            requestParams.append('operatingDay', currentDate);

            const response = await axios.requestByLink(link, { params: requestParams });

            this.mpcStatistics = response.data.data;
            this.links = response.data.links;
        }
    }
});
