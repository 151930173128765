<template>
    <div class="companies">
        <v-row>
            <v-col>
                <v-card>
                    <h1>{{ company.name }}</h1>
                    <v-checkbox
                        v-model="formCompany.messagingButtonEnabled"
                        :label="t('companies.resources.messagingButtonEnabled')"
                        data-id="ButtonEnabledChcekbox"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="formCompany.earlyTripEndButtonEnabled"
                        :label="t('companies.resources.earlyTripEndButtonEnabled')"
                        data-id="EarlyTripEndButtonEnabledChcekbox"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="formCompany.equipmentFailureButtonEnabled"
                        :label="t('companies.resources.equipmentFailureButtonEnabled')"
                        data-id="EquipmentFailureButtonEnabled"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="formCompany.hasInfotainmentScreen"
                        :label="t('companies.resources.hasInfotainmentScreen')"
                        data-id="HasInfotainmentScreen"
                    ></v-checkbox>
                    <v-row class="buttons">
                        <v-btn color="primary" data-id="BackButton" variant="outlined" @click="$router.go(-1)"
                            >{{ t('shared.resources.common.back') }}
                        </v-btn>
                        <v-btn class="white--text" color="primary" data-id="SaveButton" @click="saveCompany()"
                            >{{ t('shared.resources.common.save') }}
                        </v-btn>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <company-users></company-users>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import CompanyUsers from './CompanyUsers.vue';
import { Company } from './companiesModels';
import { useCompaniesStore } from './companiesStore';

const companiesStore = useCompaniesStore();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{
    company: Company;
}>();

const formCompany = computed(() => props.company);

async function saveCompany() {
    await companiesStore.updateCompany();
    router.go(-1);
}
</script>
