import { defineStore } from 'pinia';
import { UserNotification } from './notificationsModels';

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        notification: {
            type: 'info',
            text: ''
        } as UserNotification,
        visible: false
    }),
    actions: {
        setNotification(notification: UserNotification) {
            this.notification = notification;
            this.visible = true;
        },
        setError(text: string) {
            this.setNotification({ type: 'error', text: text });
        },
        setVisibility(visible: boolean) {
            this.visible = visible;
        }
    }
});
