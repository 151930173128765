<template>
    <div>
        <areas-form :area="areasStore.area" :existing-area="true"></areas-form>
    </div>
</template>

<script lang="ts" setup>
import AreasForm from './AreasForm.vue';
import { useRoute } from 'vue-router';
import { useAreasStore } from './areasStore';

const areasStore = useAreasStore();
const route = useRoute();

await areasStore.loadArea(Number(route.params.id));
</script>
