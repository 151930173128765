import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '../styles/vuetify.scss';
import { createVuetify, ThemeDefinition } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';

const theme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#258752',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#258752',
        warning: '#FFEEBA'
    }
};

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: theme
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        }
    },
    defaults: {
        VTextField: {
            variant: 'underlined'
        },
        VAutocomplete: {
            variant: 'underlined',
            color: 'primary'
        },
        VDatePicker: {
            color: 'primary'
        },
        VCheckbox: {
            color: 'primary'
        }
    }
});

export default vuetify;
