<template>
    <div>
        <access-tokens-form :access-token="accessTokensStore.accessToken"></access-tokens-form>
    </div>
</template>

<script lang="ts" setup>
import AccessTokensForm from './AccessTokensForm.vue';
import { useRoute } from 'vue-router';
import { useAccessTokensStore } from './accessTokensStore';
import { onMounted } from 'vue';

const accessTokensStore = useAccessTokensStore();
const route = useRoute();

const id = route.params.id.toString();
await accessTokensStore.loadAccessToken(id);

onMounted(async () => {
    await accessTokensStore.loadUsagesForAccessToken();
});
</script>
