import { AccessToken, SourceSystemLink, SourceSystemListItem } from './accessTokensModels';

export default {
    createForAccessToken(accessToken: AccessToken, allSourceSystems: SourceSystemListItem[]) {
        return accessToken.allowedSourceSystems.map(sourceSystemName => {
            const sourceSystem = allSourceSystems.find((x: any) => x.name == sourceSystemName) ?? ({} as SourceSystemListItem);

            const sourceSystemLink = this.createSourceSystemLink(sourceSystem, accessToken.token);

            return {
                name: sourceSystem.name,
                link: sourceSystemLink,
                hasLink: !!sourceSystemLink
            } as SourceSystemLink;
        });
    },
    createSourceSystemLink(sourceSystem: SourceSystemListItem, token: string) {
        if (sourceSystem.linkTemplate && token) {
            return sourceSystem.linkTemplate.replace('{token}', token);
        }
        return '';
    }
};
