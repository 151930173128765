<template>
    <div class="mpcStatistics">
        <v-main>
            <v-row class="header-row">
                <v-col class="col-lg-2 col-md-3 col-sm-5">
                    <div class="buttons">
                        <v-btn
                            :class="{
                                activeBtn: layout === 'previousDay'
                            }"
                            data-id="ChooseViewButton"
                            variant="text"
                            @click="setDaysBefore(1)"
                        >
                            {{ t('mpcStatistics.resources.yesterday') }}
                        </v-btn>
                        <v-btn
                            :class="{
                                activeBtn: layout === 'today'
                            }"
                            data-id="ChooseViewButton"
                            variant="text"
                            @click="setDaysBefore(0)"
                        >
                            {{ t('mpcStatistics.resources.today') }}
                        </v-btn>
                    </div>
                </v-col>
                <v-col class="col-md-2 col-sm-4">
                    <v-menu
                        v-model="statisticsDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props }">
                            <v-text-field
                                v-model="statisticsDate"
                                :label="t('messages.resources.date')"
                                data-id="DateTextField"
                                prepend-icon="event"
                                readonly
                                v-bind="props"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="statisticsPickerDate"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="statisticsDateMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        :transition-group="'fade-transition'"
                        type="table-thead, table-tbody"
                    ></v-skeleton-loader>
                    <template v-else>
                        <div class="v-table v-theme--light">
                            <div class="v-table__wrapper">
                                <table aria-label="MPC statistics" class="mpc-table">
                                    <thead>
                                        <tr>
                                            <td colspan="2"></td>
                                            <th class="headers-title" colspan="2" scope="colgroup">
                                                {{ t('mpcStatistics.resources.passengers') }}
                                            </th>
                                            <th class="headers-title" colspan="2" scope="colgroup">
                                                {{ t('mpcStatistics.resources.vehicle') }}
                                            </th>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr>
                                            <th scope="col"></th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.line') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.in') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.out') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.in') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.out') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.carsLeftBehind') }}
                                            </th>
                                            <th class="headers-title" scope="col">
                                                {{ t('mpcStatistics.resources.operator') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, i) in mpcStatisticsStore.mpcStatistics" :key="i">
                                            <tr class="summaries">
                                                <td>
                                                    <v-tooltip bottom>
                                                        <template #activator="{ props }">
                                                            <v-icon
                                                                v-if="tripsAreHidden(item.lineName)"
                                                                class="mr-2"
                                                                data-id="EditIcon"
                                                                style="outline: none"
                                                                v-bind="props"
                                                                icon="mdi-minus"
                                                                @click="showTripDetails(item.lineName)"
                                                            ></v-icon>
                                                            <v-icon
                                                                v-else
                                                                class="mr-2"
                                                                data-id="EditIcon"
                                                                style="outline: none"
                                                                v-bind="props"
                                                                icon="mdi-plus"
                                                                @click="showTripDetails(item.lineName)"
                                                            >
                                                            </v-icon>
                                                        </template>
                                                        <span>
                                                            {{ t('shared.resources.common.details') }}
                                                        </span>
                                                    </v-tooltip>
                                                </td>
                                                <td>{{ item.lineName }}</td>
                                                <td>
                                                    {{ item.passengersIn }}
                                                </td>
                                                <td>
                                                    {{ item.passengersOut }}
                                                </td>
                                                <td>{{ item.carsIn }}</td>
                                                <td>{{ item.carsOut }}</td>
                                                <td>
                                                    {{ item.carsLeftBehind }}
                                                </td>
                                                <td>
                                                    {{ item.operatorName }}
                                                </td>
                                            </tr>
                                            <template
                                                v-for="(departure, ii) in item.departures"
                                                :key="`${item.lineName} + ${departure.departure} + ${ii}`"
                                            >
                                                <tr :class="showTrips(item.lineName)" class="subdata">
                                                    <td colspan="8">
                                                        {{ t('mpcStatistics.resources.departure') }}:
                                                        {{ departure.departure }}
                                                        {{ t('mpcStatistics.resources.destination') }}:
                                                        {{ departure.destination }}
                                                    </td>
                                                </tr>
                                                <template
                                                    v-for="(trip, y) in departure.trips"
                                                    :key="`${trip.tripId} + ${item.lineName} + ${departure.departure} + ${y}`"
                                                >
                                                    <tr
                                                        :class="[
                                                            showTrips(item.lineName),
                                                            isLastTrip(i, item.departures.length, y, departure.trips.length)
                                                        ]"
                                                    >
                                                        <td></td>
                                                        <td>
                                                            {{ formatTime(trip.departureAt) }}
                                                        </td>
                                                        <td>
                                                            {{ trip.passengersIn }}
                                                        </td>
                                                        <td>
                                                            {{ trip.passengersOut }}
                                                        </td>
                                                        <td>
                                                            {{ trip.carsIn }}
                                                        </td>
                                                        <td>
                                                            {{ trip.carsOut }}
                                                        </td>
                                                        <td>
                                                            {{ trip.carsLeftBehind }}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import moment from 'moment';
import { FormatDateISO, ToLocalDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async';
import { useMpcStatisticsStore } from './mpcStatisticsStore';

const mpcStatisticsStore = useMpcStatisticsStore();
const { t } = useI18n();

const layout = ref('today');
const visibleTrips = ref<Array<string>>([]);
const loading = ref(false); // Initial loading is handled by Suspense
const syncTimeout = 10000;
const statisticsDateMenu = ref(false);
const statisticsDate = ref(FormatDateISO(new Date()));
const statisticsPickerDate = computed<Date>({
    get: () => {
        return new Date(statisticsDate.value);
    },
    set: (value: Date) => {
        const d = value;
        statisticsDate.value = FormatDateISO(d);
    }
});
const todayDate = ref(new Date().toISOString().slice(0, 10));

await mpcStatisticsStore.loadMpcStatistics();

const syncInterval = setIntervalAsync(async () => await mpcStatisticsStore.loadMpcStatistics(), syncTimeout);

watch(statisticsDate, async () => {
    await setStatisticsDate();
});

onBeforeUnmount(() => {
    if (syncInterval) {
        clearIntervalAsync(syncInterval);
    }
});

function showTrips(lineName: string) {
    return visibleTrips.value.includes(lineName) ? '' : 'hidden';
}

function tripsAreHidden(lineName: string) {
    return visibleTrips.value.includes(lineName);
}

function isLastTrip(i: number, departuresCount: number, y: number, tripsCount: number) {
    return i === departuresCount - 1 && y === tripsCount - 1 ? 'trips-end' : '';
}

function formatTime(date: string) {
    return ToLocalDate(date, 'time');
}

async function setDaysBefore(day: number) {
    const requestedDate = new Date();
    requestedDate.setDate(requestedDate.getDate() - day);

    const formattedDate = FormatDateISO(requestedDate);

    statisticsDate.value = formattedDate;
    setCurrentDate(formattedDate);
    setLayout();
    loading.value = true;
    await mpcStatisticsStore.loadMpcStatistics();
    loading.value = false;
}

async function setStatisticsDate() {
    setCurrentDate(statisticsDate.value);
    setLayout();
    loading.value = true;
    await mpcStatisticsStore.loadMpcStatistics();
    loading.value = false;
}

function setLayout() {
    const today = moment(todayDate.value);
    const cd = moment(mpcStatisticsStore.currentDate);
    const diff = today.diff(cd, 'days');
    if (diff === 0) {
        layout.value = 'today';
    } else if (diff === 1) {
        layout.value = 'previousDay';
    } else {
        layout.value = '';
    }
}

function showTripDetails(item: string) {
    if (tripsAreHidden(item)) {
        const index = visibleTrips.value.indexOf(item);
        visibleTrips.value.splice(index, 1);
    } else {
        visibleTrips.value.push(item);
    }
}

function setCurrentDate(date: string) {
    mpcStatisticsStore.currentDate = date;
}
</script>

<style lang="scss" scoped>
.v-table__wrapper {
    overflow-y: scroll;
    min-height: 100px;
    max-height: calc(100vh - 65px);

    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

    thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 5;

        tr:nth-child(2) th {
            position: sticky;
            top: 43px;
            background-color: white;
            box-shadow: inset 0 -2px 0 #000000;
            z-index: 5;
        }

        tr {
            th {
                font-size: 0.875rem;
                height: 48px;
                border: none !important;
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 0.875rem;
                height: 48px;
            }
        }
    }
}

.headers-title {
    text-align: center !important;
    border-bottom: none;
}

.header-row {
    margin-top: 40px;

    @media (max-height: 500px) {
        margin-top: 0;
    }
}

.buttons {
    padding-top: 14px;
}

.activeBtn {
    border-bottom: 2px solid #258752;
}

.mpc-table {
    text-align: center;
    width: 100%;
    border-spacing: 0;
}

.subdata {
    background-color: rgb(224, 224, 224);
}

.trips-end {
    border-bottom: solid;
}

.hidden {
    display: none;
}

table {
    border-collapse: collapse;
    margin: auto;
}
</style>
