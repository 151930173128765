<template>
    <div>
        <v-skeleton-loader v-if="loading" :transition-group="'fade-transition'" type="card"></v-skeleton-loader>
        <company-form v-else :company="companiesStore.company"></company-form>
    </div>
</template>

<script lang="ts" setup>
import CompanyForm from './CompanyForm.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCompaniesStore } from './companiesStore';

const companiesStore = useCompaniesStore();
const route = useRoute();

const loading = ref(true);

await companiesStore.loadCompany(Number(route.params.id));

loading.value = false;
</script>
