import axios, { AxiosResponse, Method, RawAxiosRequestConfig } from 'axios';
import { Link } from '@/shared/models';
import { parseISO } from 'date-fns/esm';

export const useAxios = function () {
    const instance = axios.create();
    instance.defaults.headers.get['x-csrf'] = 1;
    instance.defaults.headers.post['x-csrf'] = 1;
    instance.defaults.headers.put['x-csrf'] = 1;
    instance.defaults.headers.delete['x-csrf'] = 1;

    instance.interceptors.response.use(resp => {
        handleDates(resp.data);
        return resp;
    });

    const extensions = {
        requestByLink: function <T = any, R = AxiosResponse<T>, D = any>(
            link: Link,
            config?: RawAxiosRequestConfig<D> | undefined
        ): Promise<R> {
            const requestConfig = {
                method: <Method>link.method,
                url: link.href
            };

            return instance.request({ ...requestConfig, ...config });
        }
    };

    return { instance, ...extensions };
};

// handling axios date formatting
const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
    return typeof value === 'string' && ISODateFormat.test(value);
};

export const handleDates = (data: unknown) => {
    if (isIsoDateString(data)) {
        return parseISO(data);
    }
    if (data === null || data === undefined || typeof data !== 'object') return data;

    for (const [key, val] of Object.entries(data)) {
        if (isIsoDateString(val)) data[key] = parseISO(val);
        else if (typeof val === 'object') {
            handleDates(val);
        }
    }

    return data;
};
