import { defineStore } from 'pinia';
import { Stop } from './stopsModels';
import { useCommonStore } from '@/shared/commonStore';
import { StopRels } from './rels';
import { useAxios } from '@/shared/axios';
import { ListResource } from '@/shared/models';

export const useStopsStore = defineStore('stops', {
    state: () => ({
        stops: new Array<Stop>()
    }),
    actions: {
        async searchStops(query: string) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(StopRels.getStops);

            const response = await axios.requestByLink<ListResource<Stop>>(link, { params: { query: query } });
            this.stops = response.data.data;
        }
    }
});
