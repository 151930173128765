import { TransportType } from '@/shared/models';

export interface Line {
    id: number;
    privateCode: string;
    name: string;
    transportType: TransportType;
}

export const AllLines = {
    id: 0,
    privateCode: '0',
    name: 'All',
    transportType: TransportType.None
} as Line;
