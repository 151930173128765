import { useNotificationsStore } from '@/Features/Notifications/notificationsStore';
import axios from 'axios';
import i18n from '@/plugins/i18n';

export default function SetRequestInterceptor() {
    axios.interceptors.response.use(
        (response): any => {
            //in case of success pass response data next
            return response.data;
        },
        async error => {
            const notificationsStore = useNotificationsStore();

            if (error.response && error.response.status === 401) {
                await login();
            } else {
                const text = i18n.global.t('shared.resources.common.requestError');
                notificationsStore.setError(text);
                return Promise.reject(error);
            }
        }
    );
}

async function login() {
    const resp = (await axios.get('/api')) as any;
    const loginLink = resp.links.find((x: any) => x.rel == 'login');
    if (loginLink) {
        redirectToLogin(loginLink.href);
    }
}

function redirectToLogin(loginLink: string) {
    const loginUrl = loginLink + '?redirectUrl=' + window.location.href;
    window.location.replace(loginUrl);
}
