import { defineStore } from 'pinia';
import { Subscription, SubscriptionListItem, Subscriber, AuthorRole } from './subscriptionsModels';
import { DefaultPageSize, Link, ListResource, Paging, Resource } from '@/shared/models';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { SubscriptionsRels } from './rels';
import { getLink } from '@/shared/LinkHelper';
import _ from 'lodash';

const EmptySubscriber = {
    id: 0,
    phone: '',
    fullName: '',
    email: '',
    messageTypes: [],
    subscriptionTypes: []
} as Subscriber;

const EmptySubscription = {
    companies: new Array<string>(),
    lines: new Array<string>(),
    authorRoles: new Array<AuthorRole>(),
    subscribers: [{ ...EmptySubscriber }]
} as Subscription;

export const useSubscriptionsStore = defineStore('subscriptions', {
    state: () => ({
        subscriptions: new Array<SubscriptionListItem>(),
        subscription: _.cloneDeep(EmptySubscription),
        paging: {} as Paging,
        links: new Array<Link>()
    }),
    actions: {
        async loadSubscriptions(page: number, id?: number) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(SubscriptionsRels.getSubscriptions);

            const pageSize = DefaultPageSize;
            const requestParams = new URLSearchParams();
            requestParams.append('page.number', page.toString());
            requestParams.append('page.size', pageSize.toString());

            if (id) {
                requestParams.append('id', id.toString());
            }

            const response = await axios.requestByLink<ListResource<SubscriptionListItem>>(link, { params: requestParams });

            this.subscriptions = response.data.data;
            this.links = response.data.links;
            this.paging = response.data.paging ?? ({} as Paging);
        },
        async loadSubscription(id: number) {
            const axios = useAxios();

            await this.loadSubscriptions(1, id);

            const item = this.subscriptions.find(x => x.id == id);
            if (!item) throw Error(`Can't find subscription by id: ${id}`);

            const link = getLink(SubscriptionsRels.getSubscription, item.links);

            const response = await axios.requestByLink<Resource<Subscription>>(link);

            this.subscription = response.data.data ?? ({} as Subscription);
            this.links = response.data.links;
        },
        async initSubscription() {
            await this.loadSubscriptions(1);

            this.subscription = _.cloneDeep(EmptySubscription);
        },
        async createSubscription() {
            const axios = useAxios();
            const link = getLink(SubscriptionsRels.createSubscription, this.links);

            await axios.requestByLink(link, { data: this.subscription });
        },
        async updateSubscription() {
            const axios = useAxios();
            const link = getLink(SubscriptionsRels.updateSubscription, this.links);

            await axios.requestByLink(link, { data: this.subscription });
        },
        async deleteSubscription() {
            const axios = useAxios();
            const link = getLink(SubscriptionsRels.deleteSubscription, this.links);

            await axios.requestByLink(link);
        }
    }
});
