import { isEmpty, isEqual, xorWith } from 'lodash';

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const stripHTML = (text: string) => {
    return text.replace(/<\/?[^>]+>/gi, ' ');
};

export function toCamelCase(text: string) {
    return text?.charAt(0).toLowerCase() + text?.slice(1);
}
