import { defineStore } from 'pinia';
import { BaseResource, Link, Resource, Settings } from '@/shared/models';
import { useAxios } from '@/shared/axios';
import axios from 'axios';
import { getLink, hasLink } from './LinkHelper';
import { CommonRels } from './rels';

export const useCommonStore = defineStore('common', {
    state: () => ({
        settings: {} as Settings,
        translations: {} as any,
        links: new Array<Link>()
    }),
    getters: {
        getLink(state) {
            return (rel: string): Link => {
                const link = state.links.find(x => x.rel === rel);
                if (!link) throw new Error(`${rel} link not found`);
                return link;
            };
        },
        isLoggedIn(state) {
            return !hasLink('login', state.links);
        },
        loginLink(state) {
            return getLink('login', state.links);
        }
    },
    actions: {
        async loadLinks() {
            const axios = useAxios();
            const response = await axios.instance.get<BaseResource>('/api');
            this.links = response.data.links;
        },
        async loadSettings() {
            const axios = useAxios();
            const link = getLink(CommonRels.getSettings, this.links);

            const response = await axios.requestByLink<Resource<Settings>>(link);
            this.settings = response.data.data ?? ({} as Settings);
        },
        async loadTranslations() {
            const lang = await axios.get(`/jsl10n/Kolumbus.NotificationHub.Avvik.Features?camel=true&json=true`);
            const langMsg = 'kolumbus.notificationHub.avvik.features'
                .split('.')
                .reduce((acc: any, curr) => (acc?.[curr] ? acc[curr] : null), lang);

            this.translations = langMsg;
        }
    }
});
