<template>
    <div class="summary-container">
        <div v-if="company" class="companies-summary">
            <div class="title">
                {{ t('messages.resources.companies', 'Operatør') }}
            </div>
            <div class="selected-companies">
                <div class="company" @click="removeCompany">
                    <span>{{ `${company.name} (${company.id})` }}</span>
                    <span class="close-icon">
                        <v-icon icon="mdi-close" size="small"></v-icon>
                    </span>
                </div>
            </div>
            <div class="driver">
                <div class="title">
                    {{ t('messages.resources.driverId', 'Sjåfør ID') }}
                </div>
                <v-text-field v-model="model" class="driver-id" variant="outlined" color="primary" clearable></v-text-field>
            </div>
        </div>
        <div v-else class="summary-nodata">
            {{ t('messages.resources.driverScopeSummaryNoDrivers', 'Velg en operatør i tabellen og skriv inn sjåfør ID.') }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Receiver } from '../../messagesModels';

const { t } = useI18n();

const model = defineModel<string>();

interface Props {
    company: Receiver | null;
}
const emit = defineEmits<{
    (e: 'removeDriverCompany', company: Receiver);
}>();

const props = defineProps<Props>();

function removeCompany() {
    if (props.company) {
        emit('removeDriverCompany', props.company);
    }
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.companies-summary {
    padding-bottom: 16px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.driver-id {
    margin-bottom: -15px;

    :deep(.v-field) {
        background-color: $white;
    }
}

.company {
    cursor: pointer;
    display: flex;

    .close-icon {
        visibility: hidden;
        margin-left: 2px;
        margin-top: -1px;
    }

    &:hover {
        .close-icon {
            visibility: visible;
            opacity: 0.5;
        }
    }
}

.summary-nodata,
.selected-companie {
    font-size: 14px;
}
</style>
