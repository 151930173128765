<template>
    <div class="scope-container">
        <div class="scope-filters">
            <slot name="scope-select"></slot>
            <v-text-field
                v-model="search"
                class="search"
                :placeholder="t('messages.resources.searchCompany', 'Søk bedrift')"
                append-inner-icon="mdi-magnify"
                color="primary"
                variant="outlined"
            ></v-text-field>
        </div>
        <div class="scope-content">
            <v-data-table
                v-model="selectedTableRows"
                :sort-by="sortBy"
                :headers="headers"
                :items="tableRows"
                :row-props="rowProps"
                :search="search"
                select-strategy="page"
                items-per-page="-1"
                no-data-text=""
                return-object
                show-select
                fixed-header
                height="calc(100vh - 255px)"
                @click:row="updateSelectedCompanies"
            >
                <template #bottom>
                    <div class="companies-total">{{ `${t('messages.resources.total', 'Total')}: ${tableRows.length}` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Company } from '@/Features/Companies/companiesModels';
import { computed, nextTick, ref, watch } from 'vue';
import { companyReceiversEqual } from '../../helpers';
import { Receiver } from '../../messagesModels';

const { t } = useI18n();

const model = defineModel<Receiver[]>();

interface Props {
    companies: Company[];
}

interface TableRow {
    companyName: string;
    companyNumber: string;
    data: Receiver;
}

const sortBy = ref<any[]>([{ key: 'companyName', order: 'asc' }]);
const headers = computed<any[]>(() => [
    {
        title: t('messages.resources.scopeCompanyName', 'Navn'),
        key: 'companyName',
        sortable: true
    },
    {
        title: t('messages.resources.scopeCompanyNumber', 'Nummer'),
        key: 'companyNumber',
        sortable: true
    }
]);
const search = ref('');

const props = defineProps<Props>();

const tableRows = ref<TableRow[]>([]);
const selectedTableRows = ref<TableRow[]>([]);

const isTableRowsLoading = ref(false);
watch(() => props.companies, loadTableRows);
const isModelUpdating = ref(false);
watch(model, onModelUpdating, {
    deep: true
});
const isTableRowsUpdating = ref(false);
watch(selectedTableRows, onTableRowsUpdating, {
    deep: true
});

loadTableRows(props.companies);

function loadTableRows(companies: Company[]) {
    isTableRowsLoading.value = true;

    tableRows.value = companies.map(x => toCompanyReceiverTableRow(x));
    updateSelectedTableRows();
    nextTick(() => {
        isTableRowsLoading.value = false;
    });
}

function onTableRowsUpdating(newValue: TableRow[], oldValue: TableRow[]) {
    if (isModelUpdating.value) return;
    if (isTableRowsLoading.value) return;
    isTableRowsUpdating.value = true;
    updateModel(newValue, oldValue);
    nextTick(() => {
        isTableRowsUpdating.value = false;
    });
}

function onModelUpdating() {
    if (isTableRowsUpdating.value) return;
    isModelUpdating.value = true;
    updateSelectedTableRows();
    nextTick(() => {
        isModelUpdating.value = false;
    });
}

function updateSelectedTableRows() {
    selectedTableRows.value = tableRows.value.filter(x => isSelectedTableRow(x));
}

function isSelectedTableRow(tableRow: TableRow): boolean {
    return model.value?.some(x => companyReceiversEqual(x, tableRow.data)) ?? false;
}

function updateModel(newValue: TableRow[], oldValue: TableRow[]) {
    const addedValues = newValue.filter(x => !oldValue.some(y => companyReceiversEqual(x.data, y.data))).map(x => x.data);
    const removedValues = oldValue.filter(x => !newValue.some(y => companyReceiversEqual(x.data, y.data))).map(x => x.data);
    const modelValue = model.value ?? [];
    model.value = [...modelValue, ...addedValues].filter(x => !removedValues.some(y => companyReceiversEqual(x, y)));
}

function toCompanyReceiverTableRow(company: Company): TableRow {
    const companyReceiver = {
        id: company.companyNumber,
        name: company.name
    };
    return {
        companyName: company.name,
        companyNumber: company.companyNumber,
        data: companyReceiver
    };
}

function updateSelectedCompanies(_, row: any) {
    const companyToRemove = selectedTableRows.value.find(x => companyReceiversEqual(x.data, row.item.data));
    if (companyToRemove) {
        selectedTableRows.value = selectedTableRows.value.filter(x => !companyReceiversEqual(x.data, companyToRemove.data));
    } else {
        selectedTableRows.value = [row.item, ...selectedTableRows.value];
    }
}

function rowProps(row: any) {
    const isSelected = selectedTableRows.value.find(x => row.item.data.id === x.data.id);
    if (isSelected) {
        return { class: 'selected' };
    }
    return {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.scope-filters {
    display: flex;
    column-gap: 20px;

    .search {
        max-width: 350px;
    }
}

.scope-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.v-table {
    :deep(.selected) {
        background-color: $selectedGreen;

        &:hover {
            background-color: $lightGreen;
        }

        .v-icon {
            color: $primary;
        }
    }
}

.companies-total {
    color: #a0a0a0;
    font-size: 14px;
    padding: 12px 0 0 12px;
}

@media screen and (max-width: 1038px) {
    :deep(.v-table__wrapper) {
        max-height: calc(100vh - 400px) !important;
    }
}
</style>
