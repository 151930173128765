<template>
    <areas-form :area="areasStore.area" :existing-area="false"></areas-form>
</template>

<script lang="ts" setup>
import AreasForm from './AreasForm.vue';
import { useAreasStore } from './areasStore';

const areasStore = useAreasStore();

await areasStore.initNewArea();
</script>
