<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <div class="accessTokens">
            <v-row>
                <v-col>
                    <v-card>
                        <label> {{ t('accessTokens.resources.name') }}: </label>
                        <v-text-field v-model="accessToken.name" :rules="formRules" data-id="NameTextField" required></v-text-field>
                        <v-container class="sourceSystems">
                            <v-row class="sourceSystems--header">
                                <v-col md="3">
                                    <label> {{ t('accessTokens.resources.allowedSourceSystems') }}: </label>
                                </v-col>
                                <v-col md="3">
                                    <label> {{ t('accessTokens.resources.usage') }}: </label>
                                </v-col>
                            </v-row>
                            <v-row v-for="(sourceSystem, i) in sourceSystems" :key="i">
                                <v-col md="3">
                                    <v-checkbox
                                        v-model="sourceSystem.value"
                                        :hide-details="true"
                                        :label="sourceSystem.name.toString()"
                                        data-id="NameCheckbox"
                                    >
                                        <template #label>
                                            <a
                                                v-if="showSourceSystemLink(sourceSystem, accessToken)"
                                                :href="getSourceSystemLink(sourceSystem, accessToken)"
                                                target="_blank"
                                                @click.stop
                                                >{{ sourceSystem.name }}</a
                                            >
                                            <template v-else>{{ sourceSystem.name }}</template>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col md="1">
                                    <div v-if="!sourceSystem.usages && sourceSystem.value">
                                        <v-skeleton-loader type="list-item"></v-skeleton-loader>
                                    </div>
                                    <span v-if="sourceSystem.usages">
                                        {{ sourceSystem.usages.last24HoursUsage }}
                                        /
                                        {{ sourceSystem.usages.previous30DaysUsage }}</span
                                    >
                                </v-col>
                            </v-row>
                        </v-container>

                        <label> {{ t('accessTokens.resources.owner') }}: </label>
                        <v-text-field v-model="accessToken.owner" :rules="formRules" data-id="OwnerTextField" required></v-text-field>

                        <v-alert variant="tonal" color="light-blue-darken-3">
                            <template #text>
                                <!-- eslint-disable vue/no-v-html -->
                                <div v-html="t('accessTokens.resources.ownerHelpText')"></div>
                                <!--eslint-enable-->
                            </template>
                        </v-alert>

                        <label> {{ t('accessTokens.resources.token') }}: </label>
                        <v-text-field v-model="accessToken.token" :rules="formRules" data-id="TokenTextField" required></v-text-field>

                        <v-btn
                            :disabled="!valid"
                            class="white--text"
                            color="primary"
                            data-id="SaveButton"
                            @click="existingAccessToken ? saveAccessToken() : createNewAccessToken()"
                        >
                            {{ t('shared.resources.common.save') }}
                        </v-btn>
                        <v-btn
                            v-if="existingAccessToken"
                            class="deleteBtn"
                            color="error"
                            data-id="DeleteButton"
                            @click="removeAccessToken()"
                        >
                            {{ t('shared.resources.common.delete') }}
                        </v-btn>
                        <v-btn color="primary" data-id="BackButton" variant="outlined" @click="$router.go(-1)">
                            {{ t('shared.resources.common.back') }}
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { AccessToken, AccessTokenUsageCount, SourceSystemListItem } from './accessTokensModels';
import SourceSystemsBuilder from './SourceSystemsBuilder';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAccessTokensStore } from './accessTokensStore';

interface SourceSystemWithUsages extends SourceSystemListItem {
    usages: AccessTokenUsageCount;
}

const accessTokensStore = useAccessTokensStore();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{
    accessToken: AccessToken;
}>();
const valid = ref(true);
const formRules = ref([(v: any) => !!v || t('shared.resources.common.requiredProperty')]);

const accessToken = computed(() => props.accessToken);
const existingAccessToken = computed(() => Object.hasOwn(props.accessToken, 'id'));
const sourceSystems = computed(() => accessTokensStore.sourceSystems as SourceSystemWithUsages[]);

const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: {
                id: string | number;
                errorMessages: string[];
            }[];
        }>;
    }
>();

watch(() => props.accessToken.usages, initSourceSystems, { deep: true });

initSourceSystems();

function initSourceSystems() {
    sourceSystems.value.forEach(sourceSystem => {
        const match = props.accessToken.sourceSystems.find(x => x.name == sourceSystem.name);
        const isMatch = !!match;
        sourceSystem.value = isMatch;

        if (isMatch && props.accessToken.usages) {
            sourceSystem.usages = props.accessToken.usages.find(x => x.sourceSystem == sourceSystem.name) ?? ({} as AccessTokenUsageCount);
        }

        return sourceSystem;
    });
}

async function createNewAccessToken() {
    accessToken.value.allowedSourceSystems = sourceSystems.value
        .filter((x: SourceSystemListItem) => x.value)
        .map((x: SourceSystemListItem) => x.name);

    const isValid = await form.value?.validate();
    if (isValid) {
        await accessTokensStore.createAccessToken();
        router.go(-1);
    }
}

async function saveAccessToken() {
    accessToken.value.allowedSourceSystems = sourceSystems.value
        .filter((x: SourceSystemListItem) => x.value)
        .map((x: SourceSystemListItem) => x.name);

    const isValid = await form.value?.validate();
    if (isValid) {
        await accessTokensStore.updateAccessToken();
        router.go(-1);
    }
}

async function removeAccessToken() {
    const message = t('shared.resources.common.deleteNotificationMessage');
    if (confirm(message)) {
        await accessTokensStore.deleteAccessToken();
        router.go(-1);
    }
}

function getSourceSystemLink(sourceSystem: any, accessToken: any) {
    return SourceSystemsBuilder.createSourceSystemLink(sourceSystem, accessToken.token);
}

function showSourceSystemLink(sourceSystem: any, accessToken: any) {
    return !!sourceSystem.linkTemplate && !!accessToken.token && !!sourceSystem.value;
}
</script>

<style lang="scss" scoped>
.v-card {
    padding: 30px 40px;
    overflow: hidden;
}

.v-btn {
    float: right;
    margin-left: 12px;
    margin-top: 16px;

    &.deleteBtn {
        float: left;
        margin-left: 0;
    }
}

.v-alert {
    margin: 10px 0 30px;

    :deep(ul) {
        padding-left: 24px;
    }
}

.content-label {
    display: block;
    padding-bottom: 16px;
}

.accessTokens {
    margin-top: 12px;
}

.sourceSystems {
    .v-col {
        padding: 0;
    }

    .v-input.v-checkbox {
        margin-top: 0;
        padding-top: 0;
    }

    &--header {
        margin-bottom: 15px;
    }
}
</style>
