import { useAxios } from '@/shared/axios';
import { useCommonStore } from '@/shared/commonStore';
import { defineStore } from 'pinia';
import { getLink } from '@/shared/LinkHelper';
import { AreasRels } from './rels';
import { Link } from '@/shared/models';

export const useAreasStore = defineStore('areas', {
    state: () => ({
        area: {} as any,
        selectedStops: new Array<any>(),
        areas: new Array<any>(),
        selectedLines: new Array<any>(),
        links: new Array<Link>()
    }),
    actions: {
        async loadAreas() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(AreasRels.getAreas);

            const response = await axios.requestByLink(link);
            this.areas = response.data.data;
            this.links = response.data.links;
        },
        async loadArea(id: number) {
            const axios = useAxios();

            if (this.areas.length === 0) {
                await this.loadAreas();
            }

            const item = this.areas.find((x: any) => {
                return x.id === id;
            }) as any;
            if (!item) throw Error(`Can't find areas by id: ${id}`);

            const link = getLink(AreasRels.getArea, item.links);

            const response = await axios.requestByLink(link);
            this.area = response.data.data;
            this.links = response.data.links;
            this.selectedLines = response.data.data.areaLines;
            this.selectedStops = response.data.data.areaStops;
        },
        async initNewArea() {
            if (this.areas.length === 0) {
                await this.loadAreas();
            }

            this.area = {
                name: '',
                areaStops: [] as any,
                areaLines: [] as any
            };
        },
        async updateArea() {
            const axios = useAxios();
            const link = getLink(AreasRels.updateArea, this.links);

            await axios.requestByLink(link, { data: this.area });
        },
        async deleteArea() {
            const axios = useAxios();

            const link = getLink(AreasRels.deleteArea, this.links);
            await axios.requestByLink(link);
        },
        async createArea() {
            const axios = useAxios();

            const link = getLink(AreasRels.createArea, this.links);
            await axios.requestByLink(link, { data: this.area });
        },
        removeStop(id: string) {
            const stopToDelete = this.selectedStops.findIndex((stop: any) => stop.stopId === id);
            if (stopToDelete > -1) this.selectedStops.splice(stopToDelete, 1);
        },
        removeLine(id: string) {
            const lineToDelete = this.selectedLines.findIndex((line: any) => line.lineId === id);
            if (lineToDelete > -1) this.selectedLines.splice(lineToDelete, 1);
        },
        addStop(stop: any) {
            this.selectedStops.push(stop);
        },
        addLine(line: any) {
            this.selectedLines.push(line);
        }
    }
});
