import { AccessToken, AccessTokenUsageCount, AccessTokenUsageListItem } from './accessTokensModels';

export default {
    createForAccessToken(accessToken: AccessToken, allUsages: AccessTokenUsageListItem[]) {
        const usagesForToken = allUsages.find(x => x.tokenId == accessToken.id);
        if (!usagesForToken) return new Array<AccessTokenUsageCount>();

        return accessToken.allowedSourceSystems.map(sourceSystem => {
            const last24HoursUsage = usagesForToken.last24HoursUsage.find(u => u.sourceSystem == sourceSystem);
            const previous30DaysUsage = usagesForToken.previous30DaysUsage.find(u => u.sourceSystem == sourceSystem);
            return {
                sourceSystem: sourceSystem,
                last24HoursUsage: !last24HoursUsage ? 0 : last24HoursUsage.count,
                previous30DaysUsage: !previous30DaysUsage ? 0 : previous30DaysUsage.count
            } as AccessTokenUsageCount;
        });
    }
};
