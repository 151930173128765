<template>
    <div class="areas">
        <v-row>
            <v-col>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-row>
                            <v-text-field
                                v-model="area.name"
                                data-id="NameTextField"
                                :rules="formRules"
                                color="primary"
                                label="Title"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-tabs v-model="tab">
                                <v-tab>{{
                                    areasStore.selectedStops.length ? `Stops (${areasStore.selectedStops.length})` : 'Stops'
                                }}</v-tab>
                                <v-tab>{{
                                    areasStore.selectedLines.length ? `Lines (${areasStore.selectedLines.length})` : 'Lines'
                                }}</v-tab>
                            </v-tabs>
                        </v-row>
                        <v-window v-model="tab">
                            <v-window-item>
                                <v-col>
                                    <v-row>
                                        <v-autocomplete
                                            v-model="modelStops"
                                            :items="stopsStore.stops"
                                            placeholder="Search for stops"
                                            append-inner-icon="search"
                                            item-title="name"
                                            item-value="id"
                                            return-object
                                            hide-selected
                                            color="primary"
                                            data-id="NameAutocomplete"
                                        >
                                            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                                                <v-list-item
                                                    :title="item.raw.name + ' (' + item.raw.id + ')'"
                                                    v-bind="itemProps"
                                                ></v-list-item>
                                            </template>
                                        </v-autocomplete>
                                    </v-row>
                                    <v-row>
                                        <v-col class="col-8">
                                            <div id="map" style="width: 100%; height: 600px"></div>
                                        </v-col>
                                        <v-col class="col-4" overflow-y-auto>
                                            <v-row class="buttons">
                                                <v-btn data-id="ShowButton" class="white--text" color="primary" @click="showSelectedStops">
                                                    {{
                                                        showSelected
                                                            ? t('areas.resources.showSelectedStops')
                                                            : t('areas.resources.showAllStops')
                                                    }}
                                                </v-btn>
                                            </v-row>
                                            <v-row overflow-y-auto>
                                                <v-data-table
                                                    data-id="SelectedStopsTable"
                                                    :headers="headers"
                                                    hide-default-header
                                                    hide-default-footer
                                                    disable-pagination
                                                    :items="areasStore.selectedStops"
                                                    height="existingArea ? 500px : 600px"
                                                >
                                                    <template #[`item.locate`]="{ item: stop }">
                                                        <v-tooltip v-if="stop.stopId !== '0'" bottom>
                                                            <template #activator="{ props: itemProps }">
                                                                <v-icon
                                                                    data-id="SearchIcon"
                                                                    v-bind="itemProps"
                                                                    style="outline: none"
                                                                    class="mr-2"
                                                                    @click="locateStop(stop)"
                                                                    >search</v-icon
                                                                >
                                                            </template>
                                                            <span> Locate </span>
                                                        </v-tooltip>
                                                    </template>
                                                    <template #[`item.action`]="{ item: stop }">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ props: itemProps }">
                                                                <v-icon
                                                                    data-id="DeleteIcon"
                                                                    v-bind="itemProps"
                                                                    style="outline: none"
                                                                    class="mr-2"
                                                                    @click="removeSelectedStop(stop)"
                                                                    >delete</v-icon
                                                                >
                                                            </template>
                                                            <span>
                                                                {{ t('shared.resources.common.delete') }}
                                                            </span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-window-item>
                            <v-window-item>
                                <v-col>
                                    <v-row>
                                        <v-autocomplete
                                            v-model="modelLines"
                                            :items="linesStore.lines"
                                            placeholder="Search for lines"
                                            append-inner-icon="search"
                                            item-title="name"
                                            item-value="id"
                                            return-object
                                            hide-selected
                                            color="primary"
                                            data-id="LineAutocomplete"
                                        >
                                            <template #item="{ item, props: itemProps }: { item: any, props: any }">
                                                <v-list-item
                                                    :title="item.raw.name + ' (' + item.raw.id + ' ' + item.raw.privateCode + ')'"
                                                    v-bind="itemProps"
                                                ></v-list-item>
                                            </template>
                                        </v-autocomplete>
                                    </v-row>
                                    <v-row>
                                        <v-data-table
                                            data-id="SelectedLinesTable"
                                            :headers="lineHeaders"
                                            hide-default-header
                                            hide-default-footer
                                            disable-pagination
                                            :items="areasStore.selectedLines"
                                        >
                                            <template #[`item.action`]="{ item: line }">
                                                <v-tooltip bottom>
                                                    <template #activator="{ props: itemProps }">
                                                        <v-icon
                                                            data-id="DeleteIcon"
                                                            v-bind="itemProps"
                                                            style="outline: none"
                                                            class="mr-2"
                                                            @click="removeSelectedLine(line)"
                                                        >
                                                            delete
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ t('shared.resources.common.delete') }}
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>
                                    </v-row>
                                </v-col>
                            </v-window-item>
                        </v-window>
                        <v-btn
                            data-id="SaveButton"
                            class="white--text"
                            color="primary"
                            @click="existingArea ? saveArea() : createNewArea()"
                            >{{ t('shared.resources.common.save') }}</v-btn
                        >
                        <v-btn v-if="existingArea" data-id="DeleteButton" class="deleteBtn" color="error" @click="removeArea()">
                            {{ t('shared.resources.common.delete') }}
                        </v-btn>
                        <v-btn data-id="BackButton" color="primary" variant="outlined" @click="$router.go(-1)">
                            {{ t('shared.resources.common.back') }}
                        </v-btn>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import { AreaMap } from './AreaMap';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useAreasStore } from './areasStore';
import { useLinesStore } from '../Lines/linesStore';
import { useStopsStore } from '../Stops/stopsStore';
import { useCommonStore } from '@/shared/commonStore';

const commonStore = useCommonStore();
const areasStore = useAreasStore();
const linesStore = useLinesStore();
const stopsStore = useStopsStore();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{
    area: any;
    existingArea: boolean;
}>();
const modelStops = ref();
const modelLines = ref();
const showSelected = ref(true);
const valid = ref(true);
const areaMap = ref();
const tab = ref(0);
const formRules = ref([(v: any) => !!v || t('shared.resources.common.requiredProperty')]);
const headers = [
    {
        title: 'name',
        value: 'name'
    },
    {
        title: 'id',
        value: 'stopId'
    },
    {
        title: 'locate',
        value: 'locate'
    },
    {
        title: 'actions',
        value: 'action'
    }
];

const lineHeaders = [
    {
        title: 'name',
        value: 'name'
    },
    {
        title: 'id',
        value: 'lineId'
    },
    {
        title: 'actions',
        value: 'action'
    }
];

const area = ref(props.area);

const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: {
                id: string | number;
                errorMessages: string[];
            }[];
        }>;
    }
>();

if (commonStore.settings.googleMapsApiKey) {
    initGoogleMaps();
}

await linesStore.loadLines();
await stopsStore.searchStops('');

onMounted(() => {
    areaMap.value = new AreaMap();
    areaMap.value.initMap();
    areaMap.value.initLines();
});

onUnmounted(() => {
    if (areaMap.value !== null) {
        areaMap.value.destroyMap();
    }
});

watch(modelStops, val => {
    const isSelected = areasStore.selectedStops.some((stop: any) => stop.stopId === val.id);
    if (isSelected) return;
    const newStop = {
        name: val.name,
        stopId: val.id,
        publicCode: val.publicCode,
        location: val.location
    };
    areasStore.addStop(newStop);
    areaMap.value.setSelectedMarkerIcon(val.id);
    areaMap.value.setStopVisible(val.id);
    areaMap.value.center(val.id);
});

watch(modelLines, val => {
    const isSelected = areasStore.selectedLines.some((line: any) => line.lineId === val.id);
    if (isSelected) return;
    const newLine = {
        lineId: val.id,
        name: val.name,
        privateCode: val.privateCode,
        transportType: val.transportType
    };
    areasStore.addLine(newLine);
});

watch(commonStore.settings, val => {
    if (val.googleMapsApiKey) initGoogleMaps();
});

function locateStop(item: any) {
    areaMap.value.center(item.stopId ? item.stopId : item.id);
}

async function createNewArea() {
    const isValid = await form.value!.validate();
    if (isValid) {
        area.value.areaStops = areasStore.selectedStops;
        area.value.areaLines = areasStore.selectedLines;
        await areasStore.createArea();
        router.go(-1);
    }
}

async function saveArea() {
    const isValid = await form.value!.validate();
    if (isValid) {
        await areasStore.updateArea();
        router.go(-1);
    }
}

function removeSelectedStop(item: any) {
    areasStore.removeStop(item.stopId);
    areaMap.value.setStopMarkerIcon(item.stopId);
    if (!showSelected.value) {
        areaMap.value.setStopInvisible(item.stopId);
    }
    props.existingArea ? areaMap.value.center(item.stopId) : areaMap.value.zoomOut(item.stopId);
}

function removeSelectedLine(item: any) {
    areasStore.removeLine(item.lineId);
}

async function removeArea() {
    const message = t('shared.resources.common.deleteNotificationMessage');
    confirm(message) && (await areasStore.deleteArea());
    router.go(-1);
}

function showSelectedStops() {
    showSelected.value = !showSelected.value;
    areaMap.value.showOnlySelected();
}

function initGoogleMaps() {
    const existing = document.getElementById('googleMapsScript');
    if (existing) return;

    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('id', 'googleMapsScript');
    scriptElement.setAttribute('src', `//maps.googleapis.com/maps/api/js?key=${commonStore.settings.googleMapsApiKey}`);
    document.body.appendChild(scriptElement);
}
</script>

<style lang="scss" scoped>
.v-data-table {
    margin: 20px 0;
    width: 100%;
    button {
        float: right;
    }
}
.v-btn {
    float: right;
    margin-left: 12px;
    margin-top: 16px;
    &.deleteBtn {
        float: left;
        margin-left: 0;
    }
}
.leaflet-pane {
    z-index: 7;
}
</style>
