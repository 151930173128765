<template>
    <div class="accessTokens">
        <v-main>
            <v-row>
                <v-col>
                    <v-row class="controls">
                        <v-col cols="6">
                            <v-text-field
                                v-model="search"
                                append-inner-icon="search"
                                color="primary"
                                data-id="SearchTextField"
                                hide-details
                                label="Søk"
                                single-line
                                :clearable="true"
                            ></v-text-field>
                        </v-col>
                        <v-col class="actions">
                            <v-btn class="white--text" color="primary" data-id="AddNewButton" @click="createAccessToken()">
                                {{ t('shared.resources.common.addNew') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="accessTokensStore.accessTokens"
                        :items-per-page="-1"
                        :search="search"
                        data-id="DataTable"
                    >
                        <template #[`item.allowedSourceSystems`]="{ item }">
                            <template v-for="system in item.sourceSystems" :key="system.name">
                                <div>
                                    <a v-if="system.hasLink" :href="system.link" data-id="SystemLink" target="_blank">{{ system.name }}</a>
                                    <span v-else> {{ system.name }}</span>
                                </div>
                            </template>
                        </template>
                        <template #[`item.usages`]="{ item }">
                            <div v-if="!item.usages">
                                <v-skeleton-loader type="list-item"></v-skeleton-loader>
                            </div>
                            <template v-for="usage in item.usages" v-else :key="usage.sourceSystem">
                                <span>
                                    {{ usage.last24HoursUsage }} /
                                    {{ usage.previous30DaysUsage }}
                                </span>
                            </template>
                        </template>
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon
                                        class="mr-2"
                                        data-id="EditIcon"
                                        style="outline: none"
                                        v-bind="props"
                                        icon="mdi-pencil"
                                        @click="editItem(item.id)"
                                    ></v-icon>
                                </template>
                                <span>
                                    {{ t('shared.resources.common.edit') }}
                                </span>
                            </v-tooltip>
                        </template>
                        <template #bottom></template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAccessTokensStore } from './accessTokensStore';

const accessTokensStore = useAccessTokensStore();
const { t } = useI18n();
const router = useRouter();

const search = ref<string | undefined>('');
const headers = computed(() => [
    {
        title: t('accessTokens.resources.name'),
        key: 'name'
    },
    {
        title: t('accessTokens.resources.allowedSourceSystems'),
        key: 'allowedSourceSystems'
    },
    {
        title: t('accessTokens.resources.usage'),
        key: 'usages',
        sortable: false
    },
    {
        title: t('accessTokens.resources.token'),
        key: 'token',
        sortable: false
    },
    {
        title: t('accessTokens.resources.owner'),
        key: 'owner'
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action',
        sortable: false
    }
]);

await accessTokensStore.loadAccessTokens();
await accessTokensStore.loadSourceSystems();

onMounted(async () => {
    await accessTokensStore.loadUsages();
});

function editItem(item: string) {
    router.push({ path: `/accesstokens/${item}` });
}

function createAccessToken() {
    router.push({ name: 'AccessTokensCreate' });
}
</script>

<style lang="scss" scoped>
.controls {
    justify-content: flex-end;
    margin: 12px 0;

    .actions {
        display: flex;
        justify-content: flex-end;
        padding-right: 0;
    }
}

span {
    display: block;
}
</style>
