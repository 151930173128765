<template>
    <notification />
    <div class="menu-grid">
        <div class="site-logo-wrap">
            <router-link to="/">
                <img :src="logo" class="site-logo" alt="Kolumbus logo" />
            </router-link>
        </div>
        <div class="menu-wrap">
            <v-tabs v-if="isMenuVisible" v-model="activeTab">
                <v-tab v-for="(item, index) in navigationStore.menuItems.menuStrings" :key="index" :to="{ name: item }">
                    <span class="tab-label">{{ t('shared.resources.menu.' + item) }}</span>
                </v-tab>
            </v-tabs>
        </div>
        <div class="logout">
            <v-btn color="primary" variant="outlined">
                <a href="/logout">{{ t('shared.resources.common.logout') }}</a>
            </v-btn>
        </div>
    </div>
    <router-view v-slot="{ Component }">
        <suspense timeout="0">
            <template #default>
                <component :is="Component" :key="$route.path"></component>
            </template>
            <template #fallback>
                <div>
                    <v-skeleton-loader :transition-group="'fade-transition'" type="paragraph"></v-skeleton-loader>
                </div>
            </template>
        </suspense>
    </router-view>
</template>

<script lang="ts" setup>
import SetRequestInterceptor from './shared/SetRequestInterceptor';
import Notification from './Features/Notifications/Notification.vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import i18n from '@/plugins/i18n';
import { useCommonStore } from './shared/commonStore';
import { useNavigationStore } from './shared/navigationStore';
import logo from '@geta/kolumbus-assets/dist/logo-black.svg';

const commonStore = useCommonStore();
const navigationStore = useNavigationStore();
const { t } = useI18n();
const route = useRoute();

const activeTab = computed<number>({
    get: (): number => navigationStore.activeMenuTab,
    set: (data: number) => navigationStore.setTabIndex(data)
});

const isMenuVisible = computed<boolean>(() => Object.keys(navigationStore.menuItems.menuStrings).length > 1);

if (commonStore.links.length === 0) await commonStore.loadLinks();

SetRequestInterceptor();

if (commonStore.isLoggedIn) {
    await initTranslations();
    await commonStore.loadSettings();
    navigationStore.initMenu();
    navigationStore.setTabByRoute(route.name as string);
} else {
    redirectToLogin(commonStore.loginLink.href);
}

function redirectToLogin(loginLink: string) {
    const loginUrl = `${loginLink}?redirectUrl=${window.location.href}`;
    window.location.replace(loginUrl);
}

async function initTranslations() {
    await commonStore.loadTranslations();
    i18n.global.setLocaleMessage('no', commonStore.translations);
}
</script>

<style lang="scss" scoped>
.menu-grid {
    display: grid;
    grid-template-columns: 124px calc(100% - 256px) 130px;
    min-height: 48px;

    .site-logo-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .site-logo {
            width: 100px;
        }
    }

    .menu-wrap {
        padding-left: 0;
    }
}
</style>
