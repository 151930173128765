export const AccessTokensRels = {
    getAccessTokens: 'getAccessTokens',
    getAccessToken: 'getAccessToken',
    createAccessToken: 'createAccessToken',
    updateAccessToken: 'updateAccessToken',
    deleteAccessToken: 'deleteAccessToken',
    getSourceSystems: 'getSourceSystems',
    getAccessTokensUsages: 'getAccessTokensUsages',
    getAccessTokenUsage: "getAccessTokenUsage"
};
