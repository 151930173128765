<template>
    <div class="operators-summary">
        <div class="operators-title">
            {{ t('messages.resources.companies', 'Operatør') }}
        </div>
        <div class="selected-operators">
            <div v-for="operator in operators" :key="operator.id" class="operator">
                {{ `${operator.name} (${operator.id})` }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Receiver } from '../../messagesModels';
import { useI18n } from 'vue-i18n';

interface Props {
    operators: Array<Receiver>;
}
defineProps<Props>();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.operators-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 10px;
    display: flex;
    justify-content: space-between;
}

.selected-operators {
    font-size: 14px;
}
</style>
