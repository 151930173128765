<template>
    <div class="users-container">
        <div class="users-header">
            <h2>{{ t('companies.resources.companyUsersTitle') }}</h2>
        </div>
        <div class="users-search-container">
            <v-autocomplete
                v-model="modelUsers"
                :items="autocompleteUsers"
                :loading="isLoading"
                :placeholder="t('companies.resources.searchUsers', 'Søk brukere')"
                append-inner-icon="search"
                multiple
                chips
                closable-chips
                clearable
                return-object
                no-filter
                data-id="NameAutoComplete"
                @update:search="onSearchUsers"
            >
                <template #item="{ item, props: itemProps }: { item: any, props: any }">
                    <v-list-item v-bind="itemProps" :data-id="`autocomplete-${item.raw.value}`"></v-list-item>
                </template>
            </v-autocomplete>
            <v-btn
                :disabled="!modelUsers || modelUsers.length == 0"
                class="add-users-button"
                color="primary"
                data-id="AddButton"
                @click="addCompanyUsers()"
            >
                {{ t('companies.resources.addCompanyUsers') }}
            </v-btn>
        </div>
        <div class="users-table-container">
            <v-data-table
                :headers="headers"
                :items="companiesStore.companyUsers"
                :items-per-page="-1"
                data-id="DataTable"
                :loading="isTableLoading"
            >
                <template #[`item.action`]="{ item }">
                    <v-tooltip bottom>
                        <template #activator="{ props }">
                            <v-icon
                                class="mr-2"
                                data-id="EditIcon"
                                style="outline: none"
                                v-bind="props"
                                icon="mdi-delete"
                                @click="deleteCompanyUser(item)"
                            ></v-icon>
                        </template>
                        <span>{{ t('shared.resources.common.delete') }}</span>
                    </v-tooltip>
                </template>
                <template #bottom></template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { AutocompleteItem } from '@/shared/models';
import { useCompaniesStore } from './companiesStore';
import { CompanyUserListItem, User } from './companiesModels';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

const { t } = useI18n();
const companiesStore = useCompaniesStore();

const headers = computed(() => [
    {
        title: t('shared.resources.common.email'),
        key: 'email'
    },
    {
        title: t('shared.resources.common.phoneNumber'),
        key: 'phoneNumber'
    },
    {
        title: t('shared.resources.common.delete'),
        key: 'action'
    }
]);

const modelUsers = ref<AutocompleteItem<User>[]>([]);
const isLoading = ref(false);
const isTableLoading = ref(false);

const autocompleteUsers = computed<AutocompleteItem<User>[]>(() =>
    companiesStore.users.filter(x => !companiesStore.companyUsers.some(u => u.id == x.id)).map(x => toAutocompleteItem(x))
);

await companiesStore.loadCompanyUsers();

async function onSearchUsers(query: string) {
    if (!query) {
        return;
    }

    _.debounce(async val => {
        isLoading.value = true;
        await companiesStore.searchUsers(val);
        isLoading.value = false;
    }, 500)(query);
}

async function addCompanyUsers() {
    setAllLoading(true);
    for (const user of modelUsers.value) {
        await companiesStore.addCompanyUser(user.data);
    }
    modelUsers.value = [];

    await companiesStore.loadCompanyUsers();
    setAllLoading(false);
}

async function deleteCompanyUser(user: CompanyUserListItem) {
    setAllLoading(true);
    await companiesStore.deleteCompanyUser(user);
    await companiesStore.loadCompanyUsers();
    setAllLoading(false);
}

function toAutocompleteItem(user: User) {
    return { title: `${user.email} (${user.phoneNumber})`, value: user.id, data: user };
}

function setAllLoading(loading: boolean) {
    isLoading.value = loading;
    isTableLoading.value = loading;
}
</script>

<style lang="scss" scoped>
.users-search-container {
    display: flex;
}
</style>
