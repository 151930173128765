import { defineStore } from 'pinia';
import { Company, CompanyListItem, CompanyUserListItem, User } from './companiesModels';
import { Link, ListResource, Resource } from '@/shared/models';
import { getLink } from '@/shared/LinkHelper';
import { useAxios } from '@/shared/axios';
import { CompanyRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';

export const useCompaniesStore = defineStore('companies', {
    state: () => ({
        companies: new Array<CompanyListItem>(),
        companiesWithAll: new Array<CompanyListItem>(),
        company: {} as Company,
        companyUsers: new Array<CompanyUserListItem>(),
        users: new Array<User>(),
        links: new Array<Link>()
    }),
    actions: {
        async loadCompanies() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(CompanyRels.getCompanies);

            const response = await axios.requestByLink<ListResource<CompanyListItem>>(link, {
                params: {
                    includeAll: true
                }
            });
            this.companies = response.data.data.filter(x => x.id !== 0);
            this.companiesWithAll = response.data.data;
            this.links = response.data.links;
        },
        async loadCompany(id: number) {
            const axios = useAxios();

            if (this.companies.length === 0) {
                await this.loadCompanies();
            }

            const item = this.companies.find(x => {
                return x.id === id;
            });
            if (!item) throw Error(`Can't find company by id: ${id}`);

            const link = getLink(CompanyRels.getCompany, item.links);

            const response = await axios.requestByLink<Resource<Company>>(link);
            this.company = response.data.data ?? ({} as Company);
            this.links = response.data.links;
        },
        async updateCompany() {
            const axios = useAxios();
            const link = getLink(CompanyRels.updateCompany, this.links);
            await axios.requestByLink(link, { data: this.company });
        },
        async loadCompanyUsers() {
            const axios = useAxios();
            const link = getLink(CompanyRels.getCompanyUsers, this.links);
            const response = await axios.requestByLink<ListResource<CompanyUserListItem>>(link);
            this.companyUsers = response.data.data;
        },
        async searchUsers(query: string) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(CompanyRels.getUsers);

            const response = await axios.requestByLink<ListResource<User>>(link, {
                params: {
                    query: query
                }
            });

            this.users = response.data.data;
        },
        async addCompanyUser(user: User) {
            const axios = useAxios();
            const link = getLink(CompanyRels.createCompanyUser, this.links);
            await axios.requestByLink(link, {
                data: {
                    id: this.company.id,
                    userId: user.id
                }
            });
        },
        async deleteCompanyUser(user: CompanyUserListItem) {
            const axios = useAxios();
            const link = getLink(CompanyRels.deleteCompanyUser, user.links);
            await axios.requestByLink(link);
        }
    }
});
