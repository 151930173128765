<template>
    <div v-for="(group, i) in groupedDepartures" :key="i" class="departure-group">
        <div class="departures-title">
            {{ t('messages.resources.departureOperatingDay', 'Avgang') }}
            <span class="date">{{ FormatDate(group.operatingDay) }}</span>
        </div>
        <div v-for="(variant, ii) in group.variants" :key="ii" class="departure" :class="{ 'multiple-departures': canBeMutipleDepartures }">
            <div class="departure-line">{{ variant.lineName }}</div>
            <div class="departure-name">{{ variant.departure }} - {{ variant.destination }}</div>
            <div v-for="(departure, iii) in variant.departures" :key="iii" class="departure-time elevation-3">
                {{ ToLocalDate(departure.departureTime, 'time') }}
                <v-tooltip class="trip-id-tooltip" activator="parent" location="top">
                    {{ `Trip ID: ${departure.id}` }}
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DepartureReceiver } from '../../messagesModels';
import { ToLocalDate, FormatDate } from '@/shared/DateFormatHelpers';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { groupDeparturesByOperatingDay } from '../../helpers';
import { MessageNotificationType } from '../../messagesModels';

interface Props {
    departures: Array<DepartureReceiver>;
    notificationType?: MessageNotificationType;
}
const props = defineProps<Props>();

const { t } = useI18n();

const groupedDepartures = computed(() => groupDeparturesByOperatingDay(props.departures));
const canBeMutipleDepartures = computed(() => props.notificationType !== MessageNotificationType.Cancellation);
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.departure-group {
    .departures-title {
        font-size: 16px;
        font-weight: bold;
        padding: 32px 0 20px;
        display: flex;
        justify-content: space-between;

        .date {
            font-weight: normal;
        }
    }

    .departure {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 16px;
        justify-content: space-between;

        .departure-line {
            font-weight: bold;
            color: $lightGrey;
            background-color: $black;
            min-width: 30px;
            height: 24px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .departure-name {
            width: calc(100% - 110px);
            margin-left: 8px;
        }

        .departure-time {
            padding: 2px 6px;
            background-color: $white;
            border-radius: 48px;
            margin-bottom: 2px 0;
        }

        &.multiple-departures {
            flex-wrap: wrap;
            justify-content: left;

            .departure-line {
                margin-bottom: 8px;
            }

            .departure-name {
                width: calc(100% - 38px);
                margin-bottom: 8px;
            }

            .departure-time {
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
    }
}

.trip-id-tooltip {
    :deep(.v-overlay__content) {
        background-color: $white;
        color: $black;
        box-shadow: 0 8px 16px 0 #0000001a, 0 10px 10px 0 #0000001a;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
        }
    }
}
</style>
