import _ from 'lodash';
import { DepartureGroup, DepartureReceiver, DepartureVariant, LineReceiver, Receiver, StopReceiver } from './messagesModels';

export function groupDeparturesByOperatingDay(departures: DepartureReceiver[]) {
    const orderedDepartures = _.orderBy(departures, [
        dep => dep.operatingDay,
        dep => `${dep.companyNumber}${dep.lineName}${dep.departure}${dep.destination}`,
        dep => dep.departureTime
    ]);

    return orderedDepartures.reduce(function (result: DepartureGroup[], current: DepartureReceiver) {
        let departureGroup = result.find(x => x.operatingDay == current.operatingDay);
        if (!departureGroup) {
            departureGroup = {
                operatingDay: current.operatingDay,
                variants: new Array<DepartureVariant>()
            };
            result.push(departureGroup);
        }

        let variant = departureGroup.variants.find(
            x =>
                x.companyNumber == current.companyNumber &&
                x.lineName == current.lineName &&
                x.departure == current.departure &&
                x.destination == current.destination
        );
        if (!variant) {
            variant = {
                companyNumber: current.companyNumber,
                lineName: current.lineName,
                departure: current.departure,
                destination: current.destination,
                departures: new Array<DepartureReceiver>()
            };
            departureGroup.variants.push(variant);
        }

        variant.departures.push(current);

        return result;
    }, new Array<DepartureGroup>());
}

export function departureReceiversEqual(first: DepartureReceiver, second: DepartureReceiver) {
    return first.id == second.id && first.departureTime == second.departureTime && first.operatingDay == second.operatingDay;
}

export function lineReceiversEqual(first: LineReceiver, second: LineReceiver) {
    return first.id == second.id;
}

export function stopReceiversEqual(first: StopReceiver, second: StopReceiver) {
    return first.id == second.id;
}

export function companyReceiversEqual(first: Receiver, second: Receiver) {
    return first.id == second.id;
}
