<template>
    <div class="scope-container">
        <div class="scope-filters">
            <slot name="scope-select"></slot>
            <v-text-field
                v-model="search"
                class="search"
                :placeholder="t('messages.resources.searchLine', 'Søke linjer')"
                append-inner-icon="mdi-magnify"
                color="primary"
                variant="outlined"
            ></v-text-field>
        </div>
        <div class="scope-content">
            <v-data-table
                v-model="selectedTableRows"
                :sort-by="sortBy"
                :headers="headers"
                :items="tableRows"
                :row-props="rowProps"
                :loading="loading"
                :loading-text="t('messages.resources.loadingLines', 'Laster linjer')"
                :search="search"
                select-strategy="page"
                items-per-page="-1"
                no-data-text=""
                return-object
                show-select
                fixed-header
                height="calc(100vh - 255px)"
                @click:row="updateSelectedLines"
            >
                <template #bottom>
                    <div class="lines-total">{{ `${t('messages.resources.total', 'Total')}: ${tableRows.length}` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { LineReceiver } from '../../messagesModels';
import { Line } from '@/Features/Lines/linesModels';
import { computed, nextTick, ref, watch } from 'vue';
import { lineReceiversEqual } from '../../helpers';

const { t } = useI18n();

const model = defineModel<LineReceiver[]>();

interface Props {
    lines: Line[];
    loading: boolean;
}

interface TableRow {
    lineName: string;
    linePrivateCode: string;
    data: LineReceiver;
}

const sortBy = ref<any[]>([{ key: 'id', order: 'asc' }]);
const headers = computed<any[]>(() => [
    {
        title: t('messages.resources.scopeLineName', 'Rutenr.'),
        key: 'lineName',
        sortable: true,
        width: '140px',
        align: 'center'
    },
    {
        title: t('messages.resources.scopeLinePrivateCode', 'Nummer'),
        key: 'linePrivateCode',
        sortable: true
    }
]);
const search = ref('');

const props = defineProps<Props>();

const tableRows = ref<TableRow[]>([]);
const selectedTableRows = ref<TableRow[]>([]);

const isTableRowsLoading = ref(false);
watch(() => props.lines, loadTableRows);
const isModelUpdating = ref(false);
watch(model, onModelUpdating, {
    deep: true
});
const isTableRowsUpdating = ref(false);
watch(selectedTableRows, onTableRowsUpdating, {
    deep: true
});

loadTableRows(props.lines);

function loadTableRows(lines: Line[]) {
    isTableRowsLoading.value = true;

    tableRows.value = lines.map(x => toLineReceiverTableRow(x));
    updateSelectedTableRows();
    nextTick(() => {
        isTableRowsLoading.value = false;
    });
}

function onTableRowsUpdating(newValue: TableRow[], oldValue: TableRow[]) {
    if (isModelUpdating.value) return;
    if (isTableRowsLoading.value) return;
    isTableRowsUpdating.value = true;
    updateModel(newValue, oldValue);
    nextTick(() => {
        isTableRowsUpdating.value = false;
    });
}

function onModelUpdating() {
    if (isTableRowsUpdating.value) return;
    isModelUpdating.value = true;
    updateSelectedTableRows();
    nextTick(() => {
        isModelUpdating.value = false;
    });
}

function updateSelectedTableRows() {
    selectedTableRows.value = tableRows.value.filter(x => isSelectedTableRow(x));
}

function isSelectedTableRow(tableRow: TableRow): boolean {
    return model.value?.some(x => lineReceiversEqual(x, tableRow.data)) ?? false;
}

function updateModel(newValue: TableRow[], oldValue: TableRow[]) {
    const addedValues = newValue.filter(x => !oldValue.some(y => lineReceiversEqual(x.data, y.data))).map(x => x.data);
    const removedValues = oldValue.filter(x => !newValue.some(y => lineReceiversEqual(x.data, y.data))).map(x => x.data);
    const modelValue = model.value ?? [];
    model.value = [...modelValue, ...addedValues].filter(x => !removedValues.some(y => lineReceiversEqual(x, y)));
}

function toLineReceiverTableRow(line: Line): TableRow {
    const lineReceiver = {
        id: line.privateCode,
        name: line.name,
        transportType: line.transportType.toString()
    };
    return {
        lineName: line.name,
        linePrivateCode: line.privateCode,
        data: lineReceiver
    };
}

function updateSelectedLines(_, row: any) {
    const lineToRemove = selectedTableRows.value.find(x => lineReceiversEqual(x.data, row.item.data));
    if (lineToRemove) {
        selectedTableRows.value = selectedTableRows.value.filter(x => !lineReceiversEqual(x.data, lineToRemove.data));
    } else {
        selectedTableRows.value = [row.item, ...selectedTableRows.value];
    }
}

function rowProps(row: any) {
    const isSelected = selectedTableRows.value.find(x => row.item.data.id === x.data.id);
    if (isSelected) {
        return { class: 'selected' };
    }
    return {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.scope-filters {
    display: flex;
    column-gap: 20px;

    .search {
        max-width: 350px;
    }
}

.scope-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.v-table {
    :deep(.selected) {
        background-color: $selectedGreen;

        &:hover {
            background-color: $lightGreen;
        }

        .v-icon {
            color: $primary;
        }
    }
}

.lines-total {
    color: #a0a0a0;
    font-size: 14px;
    padding: 12px 0 0 12px;
}

@media screen and (max-width: 1038px) {
    :deep(.v-table__wrapper) {
        max-height: calc(100vh - 400px) !important;
    }
}
</style>
