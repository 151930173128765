import * as L from 'leaflet';
import * as PIXI from 'pixi.js';
import 'leaflet-fullscreen';
import 'leaflet-pixi-overlay';
import 'leaflet.gridlayer.googlemutant';
import { useAreasStore } from './areasStore';
import { useStopsStore } from '../Stops/stopsStore';
import { Stop } from '../Stops/stopsModels';
import { useLinesStore } from '../Lines/linesStore';
import { Line } from '../Lines/linesModels';

export class AreaMap {
    selectedStops: any[] = [];
    utils: any;
    scale: any;
    markers: any[] = [];
    markerTexture: any = PIXI.Texture.from('/img/marker-icon.png');
    swappedTexture: any = PIXI.Texture.from('/img/stop.png');
    renderer: any;
    stops = new Array<Stop>();
    lines = new Array<Line>();
    pixiOverlay: any;
    pixiContainer = new PIXI.Container();
    map: any;
    loader: any;

    center(id: string) {
        const index = this.markers.findIndex((x: any) => x.id == id);
        if (index > -1) {
            this.map.setView(this.markers[index].location, 15);
            this.map.openPopup(this.markers[index].popup);
        }
    }

    zoomOut(id: string) {
        const index = this.markers.findIndex((x: any) => x.id == id);
        if (index > -1) this.map.setView(this.markers[index].location, 10);
    }

    setStopMarkerIcon(id: string) {
        const markerIndex = this.markers.findIndex(x => x.id == id);
        this.markers[markerIndex].texture = this.markerTexture;
    }

    setSelectedMarkerIcon(id: string) {
        const markerIndex = this.markers.findIndex(x => x.id == id);
        this.markers[markerIndex].texture = this.swappedTexture;
    }

    setStopInvisible(id: string) {
        const markerIndex = this.markers.findIndex(x => x.id == id);
        this.markers[markerIndex].visible = false;
    }

    setStopVisible(id: string) {
        const markerIndex = this.markers.findIndex(x => x.id == id);
        this.markers[markerIndex].visible = true;
    }

    showOnlySelected() {
        this.markers.forEach((marker: any) => {
            if (marker.texture !== this.swappedTexture) {
                marker.visible = !marker.visible;
            }
        });
        this.map.setView([58.97, 5.7331], 10);
    }

    async getStops() {
        const stopsStore = useStopsStore();

        await stopsStore.searchStops('');
        this.stops = stopsStore.stops;
        this.drawMarkers();
    }

    destroyMap() {
        if (this.pixiContainer !== null) {
            this.pixiContainer.destroy({
                children: true,
                texture: true,
                baseTexture: true
            });
        }
    }

    drawMarkers() {
        const areasStore = useAreasStore();
        const selectedStops = areasStore.selectedStops;
        this.loader = new PIXI.Loader();
        this.loader.load(() => {
            let firstDraw = true;
            let prevZoom: any;
            this.stops.forEach((item: any) => {
                let selected = false;
                const i = selectedStops.findIndex((x: any) => x.stopId == item.id);
                if (i > -1) {
                    selected = true;
                }

                const mark: any = new PIXI.Sprite(selected ? this.swappedTexture : this.markerTexture);
                mark.anchor.set(0.5, 0.5);
                mark.name = item.name;
                mark.id = item.id;
                mark.interactive = true;
                mark.buttonMode = true;

                const popup = L.popup().setLatLng(item.location).setContent(item.name);
                mark.on('click', () => {
                    this.addStop(item, mark);
                });
                mark.on('mouseover', () => {
                    this.map.openPopup(popup);
                });
                mark.on('mouseout', () => {
                    this.map.closePopup(popup);
                });

                const markerRef = this.pixiContainer.addChild(mark);
                markerRef.location = item.location;
                markerRef.popup = popup;
                this.markers.push(markerRef);
            });

            this.pixiOverlay = (() => {
                return (L as any).pixiOverlay((utils: any, event: any) => {
                    this.utils = utils;
                    const container = utils.getContainer();
                    const zoom = utils.getMap().getZoom();
                    const renderer = utils.getRenderer();
                    const scale = utils.getScale();
                    const project = utils.latLngToLayerPoint;

                    if (firstDraw) {
                        this.markers.forEach(marker => {
                            const coords = project(marker.location);
                            marker.x = coords.x;
                            marker.y = coords.y;
                        });
                    }

                    if (firstDraw || prevZoom !== zoom) {
                        this.markers.forEach(marker => {
                            marker.scale.set(1 / scale);
                        });
                    }

                    if (event.type === 'redraw') {
                        this.markers.forEach(marker => {
                            marker.scale.set(1 / scale);
                        });
                    }
                    firstDraw = false;
                    prevZoom = zoom;
                    renderer?.render(container);
                }, this.pixiContainer);
            })();
            this.pixiOverlay.addTo(this.map);

            const ticker = new PIXI.Ticker();

            ticker.add(delta => {
                this.pixiOverlay.redraw({ type: 'redraw', delta: delta });
            });

            this.map.on('zoomstart', () => {
                ticker.start();
            });
            this.map.on('zoomend', () => {
                ticker.stop();
            });
            this.map.on('zoomanim', this.pixiOverlay.redraw, this.pixiOverlay);
            this.map.on('click', this.pixiOverlay.redraw, this.pixiOverlay);
        });
    }

    initMap() {
        const stopsStore = useStopsStore();

        this.map = L.map('map', {
            zoomControl: false
        }).setView([58.97, 5.7331], 10);

        L.gridLayer.googleMutant({ type: 'roadmap' }).addTo(this.map);
        L.control.zoom({ position: 'bottomleft' }).addTo(this.map);

        this.stops = stopsStore.stops;

        if (this.stops.length < 1) {
            this.getStops();
        } else {
            this.drawMarkers();
        }
    }

    async initLines() {
        const linesStore = useLinesStore();

        this.lines = linesStore.lines;

        if (!this.lines.length) {
            await linesStore.loadLines();
            this.lines = linesStore.lines;
        }
    }

    addStop(item: any, mark: any) {
        const areasStore = useAreasStore();
        const selectedStops = areasStore.selectedStops;
        const isSelected = selectedStops.some((stop: any) => stop.stopId === item.id);

        if (isSelected) {
            areasStore.removeStop(item.id);
            this.setStopMarkerIcon(item.id);
            return;
        }

        mark.texture = this.swappedTexture;
        this.map.setView(item.location, 13);
        const newStop = {
            name: item.name,
            stopId: item.id,
            publicCode: item.publicCode,
            location: item.location
        };
        areasStore.addStop(newStop);
    }
}
