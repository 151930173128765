<template>
    <div v-if="show" class="notification">
        <v-alert v-model="show" :type="notificationsStore.notification.type" dismissible icon="false">
            <div class="container">
                {{ notificationsStore.notification.text }}
            </div>
        </v-alert>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useNotificationsStore } from './notificationsStore';

const notificationsStore = useNotificationsStore();

const notificationTimeout = ref();

const show = computed({
    get: function (): any {
        return notificationsStore.visible;
    },
    set: function (show: boolean): void {
        notificationsStore.setVisibility(show);
    }
});

watch(show, val => {
    if (val) {
        setNotificationTimeout();
    } else {
        clearNotificationTimeout();
    }
});

function setNotificationTimeout() {
    notificationTimeout.value = setTimeout(() => {
        notificationsStore.setVisibility(false);
    }, 3000);
}

function clearNotificationTimeout() {
    clearTimeout(notificationTimeout.value);
}
</script>

<style lang="scss" scoped>
.notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: white;

    .v-alert {
        border-radius: 0;
    }
}
</style>
