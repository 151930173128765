import { Link, ListResource, Resource } from '@/shared/models';
import { defineStore } from 'pinia';
import {
    AccessToken,
    AccessTokenListItem,
    AccessTokenUsageCount,
    AccessTokenUsageListItem,
    SourceSystem,
    SourceSystemLink,
    SourceSystemListItem
} from './accessTokensModels';
import { AccessTokensRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import SourceSystemsBuilder from './SourceSystemsBuilder';
import { getLink } from '@/shared/LinkHelper';
import UsagesBuilder from './UsagesBuilder';
import _ from 'lodash';

const EmptyAccessToken = {
    allowedSourceSystems: new Array<SourceSystem>(),
    sourceSystems: new Array<SourceSystemLink>(),
    usages: new Array<AccessTokenUsageCount>()
} as AccessToken;

export const useAccessTokensStore = defineStore('accessTokens', {
    state: () => ({
        accessTokens: new Array<AccessTokenListItem>(),
        accessToken: {} as AccessToken,
        sourceSystems: new Array<SourceSystemListItem>(),
        usages: new Array<AccessTokenUsageListItem>(),
        links: new Array<Link>()
    }),
    actions: {
        async loadAccessTokens() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(AccessTokensRels.getAccessTokens);

            const response = await axios.requestByLink<ListResource<AccessTokenListItem>>(link);

            this.accessTokens = response.data.data;
            this.links = response.data.links;
        },
        async loadAccessToken(id: string) {
            const axios = useAxios();

            if (this.accessTokens.length === 0) {
                await this.loadAccessTokens();
            }

            if (this.sourceSystems.length === 0) {
                await this.loadSourceSystems();
            }

            const item = this.accessTokens.find(x => x.id === id);
            if (!item) throw Error(`Can't find access token by id: ${id}`);

            const link = getLink(AccessTokensRels.getAccessToken, item.links);

            const response = await axios.requestByLink<Resource<AccessToken>>(link);

            this.accessToken = response.data.data ?? _.cloneDeep(EmptyAccessToken);
            this.links = response.data.links;

            this.accessToken.sourceSystems = SourceSystemsBuilder.createForAccessToken(this.accessToken, this.sourceSystems);
        },
        async initAccessToken() {
            if (this.accessTokens.length === 0) {
                await this.loadAccessTokens();
            }

            if (this.sourceSystems.length === 0) {
                await this.loadSourceSystems();
            }

            this.accessToken = _.cloneDeep(EmptyAccessToken);
            this.accessToken.token = generateToken();

            function generateToken() {
                const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let array = new Uint8Array(40);
                window.crypto.getRandomValues(array);
                let uint8Array = array.map(x => validChars.charCodeAt(x % validChars.length));
                let array2 = Array.from(uint8Array);
                return String.fromCharCode.apply(null, array2);
            }
        },
        async loadUsages() {
            const axios = useAxios();

            if (this.accessTokens.length == 0) {
                await this.loadAccessTokens();
            }

            const link = getLink(AccessTokensRels.getAccessTokensUsages, this.links);

            const response = await axios.requestByLink<ListResource<AccessTokenUsageListItem>>(link);

            this.usages = response.data.data;

            this.accessTokens.forEach(x => {
                x.usages = UsagesBuilder.createForAccessToken(x, this.usages);
            });
        },
        async loadUsagesForAccessToken() {
            const axios = useAxios();

            const link = getLink(AccessTokensRels.getAccessTokenUsage, this.links);
            const response = await axios.requestByLink<Resource<AccessTokenUsageListItem>>(link);
            this.usages = [response.data.data ?? ({} as AccessTokenUsageListItem)];

            this.accessToken.usages = UsagesBuilder.createForAccessToken(this.accessToken, this.usages);
        },
        async loadSourceSystems() {
            const commonStore = useCommonStore();
            const axios = useAxios();

            if (this.accessTokens.length == 0) {
                await this.loadAccessTokens();
            }

            const link = commonStore.getLink(AccessTokensRels.getSourceSystems);

            const response = await axios.requestByLink<ListResource<SourceSystemListItem>>(link);

            this.sourceSystems = response.data.data;

            this.accessTokens.forEach(x => {
                x.sourceSystems = SourceSystemsBuilder.createForAccessToken(x, this.sourceSystems);
            });
        },
        async updateAccessToken() {
            const axios = useAxios();
            const link = getLink(AccessTokensRels.updateAccessToken, this.links);

            await axios.requestByLink(link, { data: this.accessToken });
        },
        async deleteAccessToken() {
            const axios = useAxios();
            const link = getLink(AccessTokensRels.deleteAccessToken, this.links);

            await axios.requestByLink(link);
        },
        async createAccessToken() {
            const axios = useAxios();
            const link = getLink(AccessTokensRels.createAccessToken, this.links);

            await axios.requestByLink(link, { data: this.accessToken });
        }
    }
});
