<template>
    <div class="lines-summary">
        <div class="lines-title">
            {{ t('messages.resources.line', 'Linje') }}
        </div>
        <div class="selected-lines">
            <div v-for="line in lines" :key="line.id" class="line">
                {{ line.name }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { LineReceiver } from '../../messagesModels';
import { useI18n } from 'vue-i18n';

interface Props {
    lines: Array<LineReceiver>;
}
defineProps<Props>();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.lines-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.selected-lines {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    gap: 8px;
    flex-wrap: wrap;

    .line {
        font-weight: bold;
        color: $lightGrey;
        background-color: $black;
        min-width: 30px;
        height: 24px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
