<template>
    <div class="companies">
        <v-main>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="companiesStore.companies" :items-per-page="-1" data-id="DataTable">
                        <template #[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template #activator="{ props }">
                                    <v-icon
                                        class="mr-2"
                                        data-id="EditIcon"
                                        style="outline: none"
                                        v-bind="props"
                                        icon="mdi-pencil"
                                        @click="editCompany(item.id)"
                                    ></v-icon>
                                </template>
                                <span>{{ t('shared.resources.common.edit') }}</span>
                            </v-tooltip>
                        </template>
                        <template #bottom></template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useCompaniesStore } from './companiesStore';

const companiesStore = useCompaniesStore();
const { t } = useI18n();
const router = useRouter();

const headers = computed(() => [
    {
        title: t('companies.resources.companyNumber'),
        key: 'companyNumber'
    },
    {
        title: t('companies.resources.companyName'),
        key: 'name'
    },
    {
        title: t('shared.resources.common.edit'),
        key: 'action'
    }
]);

await companiesStore.loadCompanies();

function editCompany(item: number) {
    router.push({ path: `/companies/${item}` });
}
</script>
