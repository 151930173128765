import { Link } from '@/shared/models';

export interface Company {
    id: number;
    companyNumber: string;
    name: string;
    messagingButtonEnabled: boolean;
    earlyTripEndButtonEnabled: boolean;
    equipmentFailureButtonEnabled: boolean;
    hasInfotainmentScreen: boolean;
}

export const AllCompanies = {
    id: 0,
    companyNumber: '0',
    name: 'All',
    messagingButtonEnabled: false,
    earlyTripEndButtonEnabled: false,
    equipmentFailureButtonEnabled: false,
    hasInfotainmentScreen: false
} as Company;

export interface CompanyListItem extends Company {
    links: Link[];
}

export interface CompanyUserListItem extends CompanyUser {
    links: Link[];
}

export interface CompanyUser extends User {
    companyId: string;
    companyNumber: string;
}

export interface User {
    id: string;
    userName: string;
    email: string;
    phoneNumber: string;
}
